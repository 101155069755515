<template>
  <div class="liveList">
    <div class="live_title">新建直播</div>
    <div class="addLive_form">
      <el-form :model="form" ref="form" :rules="rules">
        <el-form-item label="直播名称" prop="liveTitle">
          <el-input
            placeholder="请输入直播名称"
            v-model="form.liveTitle"
            maxlength="15"
          ></el-input>
        </el-form-item>
        <el-form-item label="封面" prop="pictureUrl">
          <el-upload
            class="avatar-uploader"
            action="/api/admin/sys-file/uploadFile"
            :headers="{
              Authorization: 'Bearer ' + token,
            }"
            :show-file-list="false"
            :on-success="handleAvatarSuccess"
            :before-upload="beforeAvatarUpload"
          >
            <img v-if="form.pictureUrl" :src="form.pictureUrl" class="avatar" />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-form-item>
        <el-form-item label="直播类型" prop="liveType">
          <el-select
            placeholder="请选择直播类型"
            @change="liveChange"
            v-model="form.liveType"
          >
            <el-option
              v-for="item in liveTypeList"
              :key="item.label"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item v-if="form.liveType == 1" label="预约时间">
          <el-date-picker
            v-model="form.appointTime"
            type="datetime"
            placeholder="选择日期时间"
            :picker-options="pickerOptionsStart"
            value-format="yyyy-MM-dd HH:mm:ss"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item v-if="form.liveType == 1" label="持续时间">
          <el-time-picker
            v-model="form.duration"
            placeholder="任意时间点"
            value-format="HH:mm:ss"
          >
          </el-time-picker>
        </el-form-item>
        <el-form-item label="直播简介">
          <el-input
            type="textarea"
            placeholder="请输入直播简介"
            v-model="form.remark"
            rows="4"
            maxlength="50"
          ></el-input>
        </el-form-item>
        <el-button class="save" type="primary" @click="saveLive('form')">{{
          form.liveType == 0 ? "保存并创建直播" : "预约直播"
        }}</el-button>
      </el-form>
    </div>
    <userManagement
      :liveId="liveId"
      :groupID="groupID"
      :liveType="form.liveType"
      @getLiveId="getLiveId"
    ></userManagement>
  </div>
</template>
  <script>
import API from "../../api/live.js";
import storeId from "../../api/storeId.js";
import userManagement from "./userManagement.vue";
export default {
  name: "liveList",
  components: { userManagement },
  data() {
    return {
      liveId: "",
      groupID: "",
      form: {
        pictureUrl: "",
        liveTitle: "",
        liveType: 0,
        remark: "",
        appointTime: "",
        duration: "00:00:00",
      },
      customId: "",
      liveTypeList: [
        {
          label: "普通直播",
          value: 0,
        },
        {
          label: "预约直播",
          value: 1,
        },
      ],
      //限制开始时间
      pickerOptionsStart: {
        disabledDate(time) {
          return (
            time.getTime() < new Date(new Date().toLocaleDateString()).getTime()
          );
        },
      },
      rules: {
        pictureUrl: [
          { required: true, message: "请上传直播封面", trigger: "change" },
        ],
        liveTitle: [
          { required: true, message: "请输入直播标题", trigger: "blur" },
          {
            min: 4,
            max: 15,
            message: "长度在 4 到 15 个字符",
            trigger: "blur",
          },
        ],
      },
    };
  },
  created() {
    this.getMenuPermission();
    this.storeId = storeId.geturlparam();
    this.token = JSON.parse(localStorage.getItem("access_token")).content;
    this.customId = JSON.parse(
      localStorage.getItem("user_info")
    ).content.customId;
  },
  methods: {
    liveChange() {
      if (this.form.liveType == 0) {
        this.form.appointTime = "";
        this.form.duration = "";
      }
    },
    getMenuPermission() {
      let t = this;
      API.getMenuPermission({
        customId: JSON.parse(localStorage.getItem("user_info")).content
          .customId,
      }).then((res) => {
        if (res.data.code == 0) {
          t.$nextTick(() => {
            if (res.data.data.indexOf("live_create") > -1) {
              t.userType = 30;
            } else {
              t.userType = "";
            }
          });
        }
      });
    },
    getLiveId(e) {
      let t = this;
      this.liveId = e.liveId;
      console.log(e);
      if (this.liveId) {
        this.$emit("getMenuName", {
          name: "liveSetting",
          liveId: e.liveId,
          pushStreamUrl: e.pushStreamUrl,
          customId: e.customId,
          groupID: e.groupID,
        });
      } else {
        this.$emit("getMenuName", {
          name: e.name,
          liveId: e.liveId,
          groupID: e.groupID,
        });
      }
    },
    // 创建直播
    saveLive(formName) {
      let t = this;
      let duration = "";
      if (this.form.liveType == 1) {
        if (!this.form.appointTime) {
          t.$message.warning("请选择预约时间");
          return;
        }
        if (!this.form.duration) {
          t.$message.warning("请选择持续时间");
          return;
        }
        if (new Date(this.form.appointTime).getTime() <= new Date().getTime()) {
          t.$message.warning("预约时间必须大于当前时间");
          return;
        }
        let durationList = this.form.duration.split(":");
        duration =
          parseInt(durationList[0]) * 60 * 60 +
          parseInt(durationList[1]) * 60 +
          parseInt(durationList[2]);
        if (duration <= 0) {
          t.$message.warning("持续时间必须大于0");
          return;
        }
      }
      this.$refs[formName].validate((valid) => {
        if (valid) {
          API.saveLive({
            storeId: this.storeId,
            liveTitle: this.form.liveTitle,
            pictureUrl: this.form.pictureUrl,
            liveType: this.form.liveType,
            customId: this.customId,
            remark: this.form.remark,
            appointTime: this.form.liveType == 1 ? this.form.appointTime : null,
            duration: this.form.liveType == 1 ? duration : null,
          }).then((res) => {
            if (res.data.code == 0) {
              t.$message.success("创建直播成功");
              t.liveId = res.data.data.id;
              t.groupID = res.data.data.groupId;
            }
          });
        } else {
          return false;
        }
      });
    },
    handleAvatarSuccess(res, file) {
      this.form.pictureUrl = res.data;
    },
    beforeAvatarUpload(file) {
      const isJPG = file.type;
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (["image/png", "image/jpeg", "image/jpg"].indexOf(isJPG) == -1) {
        this.$message.error("上传头像图片只能是 PNG JPG JPEG 格式!");
        return;
      }
      if (!isLt2M) {
        this.$message.error("上传头像图片大小不能超过 2MB!");
        return;
      }
      return isJPG && isLt2M;
    },
  },
};
</script>
  <style lang="scss" scoped>
.liveList {
  .live_title {
    font-size: 20px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #568abf;
    line-height: 32px;
    padding: 8px 24px;
    border-bottom: 1px solid #e5e5e5;
    background: #fff;
    border-radius: 16px 16px 0 0;
  }

  .addLive_form {
    background: #fff;
    padding: 8px 24px 80px;
    border-radius: 0 0 16px 16px;
    .el-form {
      width: 500px;
      margin: 24px auto;
      .el-select {
        width: 100%;
      }
    }
    .save {
      margin: 0 auto;
      display: block;
    }
  }

  .el-date-editor {
    width: 100%;
  }
}
</style>