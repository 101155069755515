/*
 * @Description: 
 * @Date: 2022-12-27 08:44:22
 * @LastEditTime: 2022-12-30 16:50:32
 * @FilePath: \massive-web-site\src\main.js
 */
import Vue from 'vue'
import App from './App.vue'
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import MetaInfo from 'vue-meta-info'
Vue.use(MetaInfo)
Vue.use(ElementUI, {
  size: "small",
  menuType: "text"
});
Vue.config.productionTip = false

new Vue({
  render: h => h(App),
}).$mount('#app')