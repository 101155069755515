<!--
 * @Description: App
 * @Date: 2022-12-27 08:44:22
 * @LastEditTime: 2023-01-05 15:04:50
 * @FilePath: \massive-web-site\src\App.vue
-->
<template>
  <div id="app" style="min-height: 100vh; background: #f7f9fa">
    <index v-if="!show"></index>
    <indexMobile v-if="show"></indexMobile>
  </div>
</template>

<script>
import index from "@/view/index.vue";
import indexMobile from "@/view/indexMobile.vue";
export default {
  name: "App",
  components: {
    index,
    indexMobile,
  },
  data() {
    return {
      show: false,
    };
  },
  created() {
    this.show = this.isMobile();
  },
  methods: {
    isMobile() {
      let flag = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      return flag;
    },
  },
};
</script>

<style>
html {
  height: 100%;
  background: #97c2dc;
}
body {
  width: 100%;
  height: 100%;
  overflow: scroll;
  /* overflow-x: hidden; */
  margin: 0;
}
body::-webkit-scrollbar {
  width: 4px;
}
body::-webkit-scrollbar-thumb {
  border-radius: 10px;
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  background: rgba(0, 0, 0, 0.2);
}
body::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  border-radius: 0;
  background: rgba(0, 0, 0, 0.1);
}
.addLive_form .avatar-uploader .el-upload {
  display: block;
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  width: 214px;
  height: 120px;
  top: 32px;
  left: -50px;
  margin-bottom: 30px;
  overflow: hidden;
}
.addLive_form .avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.addLive_form .avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 214px;
  height: 120px;
  line-height: 120px;
  text-align: center;
}
.addLive_form .avatar {
  width: 214px;
  height: 120px;
  display: block;
}

.userManagement .v-modal {
  z-index: 2013 !important;
  display: none;
}
.word1 {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.word2 {
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.word3 {
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
</style>
