/*
 * @Description: axios请求封装
 * @Date: 2022-12-27 11:00:31
 * @LastEditTime: 2023-02-01 09:34:24
 * @FilePath: \massive-web-site\src\utils\request.js
 */
import axios from "axios";
import { setStore, getStore, removeStore, getAllStore, clearStore } from '@/utils/store.js'
import { Message, MessageBox } from "element-ui";

axios.defaults.timeout = 30000;
// 返回其他状态吗
axios.defaults.validateStatus = function (status) {
  return status >= 200 && status <= 500; // 默认的
};
// 跨域请求，允许保存cookie
axios.defaults.withCredentials = true;


// HTTPrequest拦截
axios.interceptors.request.use(
  config => {
    const isToken = (config.headers || {}).isToken === false;
    let token = getStore({ name: 'access_token' });
    //用api转发后端接口
    if (!config.url.includes('http://') && !config.url.includes('https://')) {
      config.url = '/api' + config.url
    }
    if (token && !isToken) {
      config.headers["Authorization"] = "Bearer " + token; // token
    }
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

// HTTPresponse拦截
axios.interceptors.response.use(
  res => {
    const status = Number(res.status) || 200;
    const message = res.data.msg || "网络错误"

    // 后台定义 424 针对令牌过去的特殊响应码
    if (status === 424) {
      MessageBox.confirm("令牌状态已过期，请重新登录", "系统提示", {
        confirmButtonText: "确认",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          setStore({
            name: "access_token",
            content: "",
          });
          setStore({
            name: "user_info",
            content: "",
          });
          location.reload();
        })
        .catch(() => { });
      return;
    }

    if (status !== 200 || res.data.code === 1) {
      Message({
        message: message,
        type: "error"
      });
      return Promise.reject(new Error(message));
    }
    return res;
  },
  error => {
    return Promise.reject(new Error(error));
  }
);

export default axios;
