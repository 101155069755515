<template>
  <div class="liveList">
    <div class="live_title">
      <div>历史记录</div>
      <el-button type="primary" v-if="userType == 30" @click="toAddLive"
        >新建直播</el-button
      >
    </div>
    <div class="liveList_search">
      <el-select v-model="time" placeholder="请选择">
        <el-option
          v-for="item in timeList"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>
      <el-select v-model="see" placeholder="请选择">
        <el-option
          v-for="item in seeList"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>
      <el-input
        v-model="liveTitle"
        placeholder="请输入直播间名称"
        class="input-with-select"
      >
        <el-button
          slot="append"
          @click="search"
          icon="el-icon-search"
        ></el-button>
      </el-input>
    </div>
    <div class="liveList_data">
      <div v-for="(item, index) in tableData">
        <div>
          <div>{{ item.liveTitle }}</div>
          <div>{{ item.name }}</div>
          <div>直播时长：{{ item.liveTime }}</div>
          <div>开始时间：{{ item.startTime }}</div>
          <div>结束时间：{{ item.endTime }}</div>
        </div>
        <div class="end" @click="deleteHistory(item)">删除</div>
      </div>
    </div>
    <div class="block">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="current"
        :page-sizes="[6, 15, 30, 60]"
        :page-size="size"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
  </div>
</template>
  <script>
import API from "../../api/live.js";
import storeId from "../../api/storeId.js";
export default {
  name: "liveList",
  data() {
    return {
      current: 1,
      size: 15,
      storeId: "",
      liveTitle: "",
      tableData: [],
      total: 0,
      time: 0,
      timeList: [
        {
          label: "按时间顺序排序",
          value: 0,
        },
      ],
      see: 0,
      seeList: [
        {
          label: "全部",
          value: 0,
        },
        {
          label: "我的",
          value: 1,
        },
      ],
    };
  },
  created() {
    this.getMenuPermission();
    this.storeId = storeId.geturlparam();
    this.historyLivePage();
  },
  methods: {
    getMenuPermission() {
      let t = this;
      API.getMenuPermission({
        customId: JSON.parse(localStorage.getItem("user_info")).content
          .customId,
      }).then((res) => {
        if (res.data.code == 0) {
          t.$nextTick(() => {
            if (res.data.data.indexOf("live_create") > -1) {
              t.userType = 30;
            } else {
              t.userType = "";
            }
          });
        }
      });
    },
    search() {
      this.current = 1;
      this.historyLivePage();
    },
    toAddLive() {
      this.$emit("getMenuName", { name: "addLive" });
    },
    handleSizeChange(val) {
      this.size = val;
      this.historyLivePage();
    },
    handleCurrentChange(val) {
      this.current = val;
      this.historyLivePage();
    },
    // 时长
    duration(time) {
      let minuteTime = 0; // 分
      let hourTime = 0; // 小时
      let secondTime = parseInt(time / 1000);
      if (secondTime > 60) {
        minuteTime = parseInt(secondTime / 60);
        secondTime = parseInt(secondTime % 60);
        if (minuteTime > 60) {
          hourTime = parseInt(minuteTime / 60);
          minuteTime = parseInt(minuteTime % 60);
        }
      }
      secondTime =
        parseInt(secondTime) > 9
          ? parseInt(secondTime)
          : "0" + parseInt(secondTime);
      minuteTime =
        parseInt(minuteTime) > 9
          ? parseInt(minuteTime)
          : "0" + parseInt(minuteTime);
      hourTime =
        parseInt(hourTime) > 9 ? parseInt(hourTime) : "0" + parseInt(hourTime);

      return hourTime + ":" + minuteTime + ":" + secondTime;
    },
    deleteHistory(row) {
      let t = this;
      //删除确认框
      t.$confirm("此操作将删除此条记录, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          API.deleteHistory(row.id).then((res) => {
            if (res.data.code == 0) {
              t.$message.success("删除成功");
              t.historyLivePage();
            }
          });
        })
        .catch(() => {});
    },
    historyLivePage() {
      let t = this;
      API.historyLivePage({
        current: this.current,
        size: this.size,
        storeId: this.storeId,
        liveTitle: this.liveTitle,
        // mobile: "string",
        // name:
        //   this.see == 1
        //     ? JSON.parse(localStorage.getItem("user_info")).content.name
        //     : null,
        // liveType: 0,
        // status: 0,
        // startDateTimes: ["2023-02-22T03:24:54.116Z"],
        // endDateTimes: ["2023-02-22T03:24:54.116Z"],
        onlyMin: this.see == 1 ? true : false,
      }).then((res) => {
        if (res.data.code == 0) {
          res.data.data.records.map((item) => {
            if (item.endTime) {
              item.liveTime = t.duration(
                new Date(item.endTime).getTime() -
                  new Date(item.startTime).getTime()
              );
            } else {
              item.liveTime = "正在直播";
            }
          });
          t.tableData = res.data.data.records;
          t.current = res.data.data.current;
          t.size = res.data.data.size;
          t.total = res.data.data.total;
          console.log(t.tableData);
        }
      });
    },
  },
};
</script>
  <style lang="scss" scoped>
.liveList {
  .el-select {
    margin-right: 10px;
  }
  .block {
    display: flex;
    justify-content: center;
  }
  .live_title {
    font-size: 20px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #568abf;
    line-height: 32px;
    padding: 8px 24px;
    border-bottom: 1px solid #e5e5e5;
    display: flex;
    justify-content: space-between;
  }
  .liveList_search {
    margin: 16px 24px;
    .el-input {
      width: 370px;
    }
  }
  .liveList_data {
    margin: 0 24px;
    overflow-y: auto;
    & > div {
      float: left;
      margin-right: 24px;
      margin-bottom: 24px;
      & > div:nth-of-type(1) {
        width: 349px;
        // height: 286px;
        background: #ffffff;
        box-shadow: 4px 4px 12px 0px rgba(0, 0, 0, 0.2);
        border-radius: 10px 10px 0px 0px;
        padding: 14px;
        box-sizing: border-box;
        position: relative;
        img {
          width: 310px;
          height: 177px;
          border-radius: 10px;
          display: block;
          margin: 0 auto;
        }
        div {
          width: 310px;
          margin: 0 auto;
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #0f1b41;
          line-height: 24px;
        }
        div:nth-of-type(1) {
          width: 310px;
          font-size: 21px;
          margin: 14px auto;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #0f1b41;
          line-height: 32px;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }

        .status0,
        .status1 {
          position: absolute;
          right: 0;
          top: 0;
          z-index: 2;
          padding: 0 19px;
          height: 26px;
          background: #ffbd5a;
          box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.25);
          border-radius: 6px 0px 6px 0px;
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #ffffff;
          line-height: 26px;
          text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
        }
        .status1 {
          background: #568abf;
        }
      }
      & > div:nth-of-type(2) {
        width: 349px;
        height: 58px;
        line-height: 58px;
        text-align: center;
        background: #e6eef4;
        border-radius: 0px 0px 10px 10px;
        font-size: 18px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #568abf;
        position: relative;
        cursor: pointer;
        z-index: 5;
      }
      & > div.end {
        background: #e5e5e5;
        color: #e31c1c;
      }
    }
  }
}
</style>