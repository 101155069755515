<!--
 * @Description: 手机端首页
 * @Date: 2023-01-05 15:04:23
 * @LastEditTime: 2023-01-05 17:00:23
 * @FilePath: \massive-web-site\src\view\indexMobile.vue
-->
<template>
  <div class="mobile_index">
    <div class="top">
      <img src="../../public/static/img/logo.jpg" class="img" />
      <div class="text">课内海量阅读</div>
    </div>
    <div class="main">
      <div class="top">
        <div class="text">课程学习</div>
        <div class="button" @click="copy">点击复制网址</div>
      </div>
      <div class="content">
        请使用电脑浏览器打开，进入官方学习网站，开始上课学习～
      </div>
    </div>
    <div class="empty_box">
      <img
        class="img"
        src="../../public/static/img/Empty_state_money.png"
        alt=""
      />
      <div class="text1">敬请期待</div>
      <div class="text2">手机版官方学习网站……</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "app",
  data() {
    return {};
  },
  created() {
    this.scale();
  },
  methods: {
    scale() {
      //获取屏幕宽度
      const clientWidth = document.documentElement.clientWidth;
      //对屏幕宽度进行判断
      if (clientWidth < 1024) {
        let html = document.getElementsByTagName("html")[0];
        html.style.fontSize = (clientWidth / 1080) * 3 + "px";
        html.style.background = "#f5f9fd";
      }
    },
    copy() {
      let str = window.location.href;
      let oInput = document.createElement("input");
      oInput.value = str;
      document.body.appendChild(oInput);
      oInput.select(); // 选择对象;
      document.execCommand("Copy"); // 执行浏览器复制命令
      this.$message({
        message: "复制成功",
        type: "success",
      });
      oInput.remove();
    },
  },
};
</script>

<style lang="less">
.mobile_index {
  width: 100%;
  height: 100%;
  background-color: #f5f9fd;
  .top {
    padding: 12rem;
    display: flex;
    justify-content: flex-start;
    .img {
      width: 32rem;
      height: 32rem;
      border-radius: 16rem;
    }
    .text {
      width: 128rem;
      height: 32rem;
      font-size: 16rem;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #8c8c8c;
      line-height: 32rem;
      margin-left: 8rem;
    }
  }
  .main {
    width: 100%;
    height: 120rem;
    background: #ffffff;
    border-radius: 10rem;
    padding: 6rem 0 6rem 0;
    .top {
      width: 335rem;
      height: 32rem;
      display: flex;
      justify-content: space-between;
      .text {
        font-size: 20rem;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #0f1b41;
        line-height: 32rem;
      }
      .button {
        width: 108rem;
        height: 25rem;
        border-radius: 13rem;
        border: 1rem solid #568abf;
        font-size: 14rem;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #568abf;
        line-height: 25rem;
        text-align: center;
      }
    }
    .content {
      width: 335rem;
      height: 48rem;
      font-size: 16rem;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #0f1b41;
      line-height: 24rem;
      margin-left: 20rem;
    }
  }
  .empty_box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .img {
      width: 202rem;
      height: 202rem;
    }
    .text1 {
      width: 64rem;
      height: 24rem;
      font-size: 16rem;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #568abf;
      line-height: 24rem;
    }
    .text2 {
      width: 132rem;
      height: 24rem;
      font-size: 12rem;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #568abf;
      line-height: 24rem;
    }
  }
}
</style>