<!--
 * @Description: pdf显示
 * @Date: 2022-12-30 16:50:56
 * @LastEditTime: 2023-01-03 09:45:54
 * @FilePath: \massive-web-site\src\components\pdfViewer.vue
-->
<template>
  <div style="position: relative" id="pdfViewer">
    <!-- <input type="checkbox" v-model="show" /> -->
    <div
      class="big_box"
      :style="isFull ? 'bottom: 10%;left: 35%;' : 'bottom: 20%;left: 20%;'"
    >
      <el-button-group>
        <el-button icon="el-icon-arrow-left" @click="upPage">上一页</el-button>
        <el-button @click="donwPage"
          >下一页<i class="el-icon-arrow-right el-icon--right"></i
        ></el-button>
      </el-button-group>
      <span style="margin: 0 16px">{{ page }}/{{ numPages }}</span>
      <el-button @click="rotate += 90">&#x27F3;</el-button>
      <el-button @click="rotate -= 90">&#x27F2;</el-button>
      <el-button @click="scaleD" class="el-icon-zoom-in"></el-button>
      <el-button @click="scaleX" class="el-icon-zoom-out"></el-button>
      <el-button
        @click="fullScreen"
        class="el-icon-full-screen"
        v-show="!isFull"
      ></el-button>
      <el-button
        @click="fullScreen"
        class="el-icon-bottom-left"
        v-show="isFull"
      ></el-button>
      <!-- <el-button @click="$refs.pdf.print()">print</el-button> -->
    </div>
    <div style="width: 100%" class="pdf_box">
      <div
        v-if="loadedRatio > 0 && loadedRatio < 1"
        style="background-color: green; color: white; text-align: center"
        :style="{ width: loadedRatio * 100 + '%' }"
      >
        {{ Math.floor(loadedRatio * 100) }}%
      </div>
      <pdf
        v-if="show"
        ref="pdf"
        style="width: 100%"
        :src="src"
        :page="page"
        :rotate="rotate"
        @password="password"
        @progress="loadedRatio = $event"
        @error="error"
        @num-pages="numPages = $event"
        @link-clicked="page = $event"
      ></pdf>
    </div>
  </div>
</template>
<script>
import pdf from "vue-pdf";

export default {
  props: ["src"],
  components: {
    pdf: pdf,
  },
  data() {
    return {
      show: true,
      isFull: false,
      loadedRatio: 0,
      page: 1,
      numPages: 0,
      rotate: 0,
      // 放大系数 默认百分百
      scale: 100,
    };
  },
  created() {
    // 监听事件
    window.addEventListener("resize", this.onresize);
  },
  beforeDestroy() {
    // 取消监听事件
    window.removeEventListener("resize", this.onresize);
  },
  methods: {
    // 全屏
    // 监听是否全屏状态
    onresize(event) {
      // 利用屏幕分辨率和window对象的内高度来判断兼容IE
      let winFlag = window.innerHeight === window.screen.height;
      // 利用window全屏标识来判断 -- IE无效
      let isFull = window.fullScreen || document.webkitIsFullScreen;

      if (isFull === undefined) {
        this.isFull = winFlag;
      } else {
        this.isFull = winFlag || isFull;
      }
      console.log(winFlag); // true全屏   false不是全屏
    },
    fullScreen() {
      //判断各种浏览器 -全屏
      function launchFullScreen(element) {
        if (element.requestFullscreen) {
          element.requestFullscreen();
        } else if (element.mozRequestFullScreen) {
          element.mozRequestFullScreen();
        } else if (element.webkitRequestFullScreen) {
          element.webkitRequestFullScreen();
        } else if (element.msRequestFullscreen) {
          element.msRequestFullscreen();
        } else {
          wtx.info("当前浏览器不支持部分全屏！");
        }
      }
      //判断各种浏览器 -退出全屏
      function exitFullscreen(element) {
        console.log("exitFullscreen", element);
        if (document.exitFullscreen) {
          document.exitFullscreen();
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else if (document.webkitExitFullscreen) {
          document.webkitExitFullscreen();
        } else if (document.msExitFullscreen) {
          document.msExitFullscreen();
        }
      }

      this.isFull = !this.isFull;
      if (this.isFull) {
        // this.postFullScreen();
        launchFullScreen(document.getElementById("pdfViewer")); // 单独元素显示全屏
        return false;
      } else {
        exitFullscreen(document.getElementById("pdfViewer"));
      }
    },
    //放大
    scaleD() {
      this.scale += 5;
      this.$refs.pdf.$el.style.width = parseInt(this.scale) + "%";
    },
    //缩小
    scaleX() {
      // scale 是百分百展示 不建议缩放
      if (this.scale == 100) {
        return;
      }
      this.scale += -5;
      console.log(parseInt(this.scale) + "%");
      this.$refs.pdf.$el.style.width = parseInt(this.scale) + "%";
    },
    password: function (updatePassword, reason) {
      updatePassword(prompt('password is "test"'));
    },
    error: function (err) {
      console.log(err);
    },
    upPage() {
      if (this.page > 1) {
        this.page = this.page - 1;
      }
    },
    donwPage() {
      if (this.page < this.numPages) {
        this.page = this.page + 1;
      }
    },
  },
};
</script>

<style lang="css" scoped>
.big_box {
  position: fixed;
  z-index: 10;
}
#pdfViewer {
  width: 100%;
  height: 100%;
  overflow-x: scroll;
  overflow-y: scroll;
}
</style>