/*
 * @Description: 课程
 * @Date: 2022-12-28 10:17:45
 * @LastEditTime: 2023-01-02 16:23:27
 * @FilePath: \massive-web-site\src\api\lesson.js
 */

import request from '@/utils/request.js'

const url = "/course/pc"

const API = {
    // 兑换课程
    exchangeCourse: data => request({
        url: url + '/exchangeCourse',
        method: 'post',
        data
    }),
    // 用户的课程目录
    treeTable: params => request({
        url: url + '/treeTable',
        method: 'get',
        params
    }),
    // 获取教师详情
    teacherInfo: params => request({
        url: url + '/teacherInfo',
        method: 'get',
        params
    }),
    // 用户的课程信息
    getUserCoursePage: params => request({
        url: url + '/getUserCoursePage',
        method: 'get',
        params
    }),
    // 用户的课程详情
    getUserCourseDetail: params => request({
        url: url + '/getUserCourseDetail',
        method: 'get',
        params
    }),
    // 课程的教师信息
    courseTeacher: params => request({
        url: url + '/courseTeacher',
        method: 'get',
        params
    }),
    // 通过id下载文件
    download: params => request({
        url: '/admin/sys-file/download',
        method: 'get',
        params
    }),
}

export default API