<template>
  <div class="live" v-if="menuName">
    <menuList
      class="frist"
      @getMenuName="getMenuName"
      :menuName="menuName"
      v-if="menuName != 'liveSetting'"
    ></menuList>
    <addLive v-if="menuName == 'addLive'" @getMenuName="getMenuName"></addLive>
    <div
      class="live_index_warp"
      v-if="
        menuName == 'myLive' ||
        menuName == 'liveList' ||
        menuName == 'historicalRecord'
      "
    >
      <myLive v-if="menuName == 'myLive'" @getMenuName="getMenuName"></myLive>
      <liveList
        v-if="menuName == 'liveList'"
        @getMenuName="getMenuName"
      ></liveList>
      <historicalRecord
        v-if="menuName == 'historicalRecord'"
        @getMenuName="getMenuName"
      ></historicalRecord>
    </div>

    <liveSetting
      @getMenuName="getMenuName"
      v-if="menuName == 'liveSetting'"
      :liveId="liveId"
      :customId="customId"
      :pushStreamUrl="pushStreamUrl"
      :groupID="groupID"
      :startTime="startTime"
      :teacherName="teacherName"
    ></liveSetting>
  </div>
</template>
<script>
import addLive from "./addLive.vue";
import menuList from "./menuList.vue";
import myLive from "./myLive.vue";
import liveList from "./liveList.vue";
import historicalRecord from "./historicalRecord.vue";
import liveSetting from "./liveSetting.vue";
import API from "../../api/live.js";
export default {
  naem: "live",
  components: {
    addLive,
    myLive,
    menuList,
    liveList,
    historicalRecord,
    liveSetting,
  },
  data() {
    return {
      menuName: "liveList",
      liveId: "",
      customId: "",
      pushStreamUrl: "",
      groupID: "",
      startTime: "",
      teacherName: "",
    };
  },
  created() {},

  methods: {
    getMenuName(e) {
      this.menuName = e.name;
      this.liveId = e.liveId;
      this.customId = e.customId;
      this.pushStreamUrl = e.pushStreamUrl;
      this.groupID = e.groupID;
      this.startTime = e.startTime;
      this.teacherName = e.teacherName;
      console.log(e);
    },
  },
};
</script>
<style lang="scss" scoped>
.live {
  width: 1455.4px;
  min-width: 1455.4px;
  border-radius: 16px !important;
  margin: 10px auto;
  display: flex;
  padding: 12px 0;
  background: #f7f9fa;
  & > div.frist {
    width: 220px;
    border-radius: 16px;
    overflow: hidden;
  }
  & > div:nth-last-of-type(1) {
    margin-left: 40px;
    flex: 1;
  }
  .live_index_warp {
    overflow-y: auto;
    height: 824px;
    padding-bottom: 20px;
  }
  .live_index_warp::-webkit-scrollbar {
    width: 1px;
  }
  .live_index_warp::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: rgba(0, 0, 0, 0);
  }
  .live_index_warp::-webkit-scrollbar-track {
    border-radius: 0;
    background: rgba(0, 0, 0, 0);
  }
}
</style>