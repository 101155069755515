<template>
  <div class="liveList">
    <div class="live_title">
      <div>我的直播</div>
      <el-button type="primary" v-if="userType == 30" @click="toAddLive"
        >新建直播</el-button
      >
    </div>
    <div class="liveList_search">
      <el-select v-model="time" placeholder="请选择">
        <el-option
          v-for="item in timeList"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>
      <el-input
        v-model="liveTitle"
        placeholder="请输入直播间名称"
        class="input-with-select"
      >
        <el-button
          slot="append"
          @click="search"
          icon="el-icon-search"
        ></el-button>
      </el-input>
    </div>
    <div class="myList_data">
      <div v-for="(item, index) in tableData">
        <div
          :class="
            item.status == 0
              ? 'status status0'
              : item.status == 1
              ? 'status status1'
              : 'status status2'
          "
        >
          {{
            item.status == 0 && item.liveType == 0
              ? "待直播"
              : item.status == 0 && item.liveType == 1
              ? "预约直播"
              : item.status == 1
              ? "正在直播"
              : "已结束"
          }}
        </div>
        <div>
          <div>
            <img :src="item.pictureUrl" />
          </div>
          <div>
            <div>{{ item.liveTitle }}</div>
            <div>创建时间：{{ item.createTime }}</div>
            <div v-if="item.liveType == 1">
              预约时间：{{ item.appointTime }}
            </div>
            <div v-if="item.liveType == 1">
              预计直播时长：{{ item.durationTime }}
            </div>
          </div>
          <div class="cz" v-if="item.status == 0">
            <div @click="enterLive(item)">开始直播</div>
            <div @click="edit(item)">编辑</div>
            <div @click="userManage(item)">用户管理</div>
          </div>
          <div class="cz" v-if="item.status == 1">
            <div @click="enterLive(item)">进入直播</div>
            <div @click="userManage(item)">用户管理</div>
          </div>
          <div class="cz" v-if="item.status == 2">
            <div @click="copyLive(item)">再次发起直播</div>
          </div>
        </div>
      </div>
    </div>
    <div class="block">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="current"
        :page-sizes="[6, 15, 30, 60]"
        :page-size="size"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
    <el-dialog
      title="编辑直播"
      :visible.sync="isEdit"
      width="640px"
      :before-close="handleClose"
    >
      <div class="addLive_form">
        <el-form :model="form" ref="form" :rules="rules">
          <el-form-item label="直播名称" prop="liveTitle">
            <el-input
              placeholder="请输入直播名称"
              v-model="form.liveTitle"
              maxlength="15"
            ></el-input>
          </el-form-item>
          <el-form-item label="封面" prop="pictureUrl">
            <el-upload
              class="avatar-uploader"
              action="/api/admin/sys-file/uploadFile"
              :headers="{
                Authorization: 'Bearer ' + token,
              }"
              :show-file-list="false"
              :on-success="handleAvatarSuccess"
              :before-upload="beforeAvatarUpload"
            >
              <img
                v-if="form.pictureUrl"
                :src="form.pictureUrl"
                class="avatar"
              />
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
          </el-form-item>
          <el-form-item label="直播类型" prop="liveType">
            <el-select
              placeholder="请选择直播类型"
              disabled
              v-model="form.liveType"
            >
              <el-option
                v-for="item in liveTypeList"
                :key="item.label"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item v-if="form.liveType == 1" label="预约时间">
            <el-date-picker
              v-model="form.appointTime"
              type="datetime"
              disabled
              placeholder="选择日期时间"
              :picker-options="pickerOptionsStart"
              value-format="yyyy-MM-dd HH:mm:ss"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item v-if="form.liveType == 1" label="持续时间">
            <el-time-picker
              v-model="form.duration"
              disabled
              placeholder="任意时间点"
              value-format="HH:mm:ss"
            >
            </el-time-picker>
          </el-form-item>
          <el-form-item label="直播简介">
            <el-input
              type="textarea"
              placeholder="请输入直播简介"
              v-model="form.remark"
              rows="4"
              maxlength="50"
            ></el-input>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button type="primary" @click="updateLive('form')">确 定</el-button>
      </span>
    </el-dialog>
    <userManagement
      :liveId="liveId"
      :nameLive="'myLive'"
      @getLiveId="getLiveId"
    ></userManagement>
  </div>
</template>
    <script>
import API from "../../api/live.js";
import storeId from "../../api/storeId.js";
import userManagement from "./userManagement.vue";
export default {
  name: "liveList",
  components: { userManagement },
  data() {
    return {
      userType: "",
      liveId: "",
      current: 1,
      size: 15,
      storeId: "",
      customId: "",
      liveTitle: "",
      tableData: [],
      total: 0,
      time: 0,
      timeList: [
        {
          label: "按时间顺序排序",
          value: 0,
        },
      ],

      isEdit: false,
      form: {
        id: "",
        pictureUrl: "",
        liveTitle: "",
        liveType: 0,
        remark: "",
        appointTime: "",
        duration: "00:00:00",
      }, //限制开始时间
      pickerOptionsStart: {
        disabledDate(time) {
          return (
            time.getTime() < new Date(new Date().toLocaleDateString()).getTime()
          );
        },
      },
      rules: {
        pictureUrl: [
          { required: true, message: "请上传直播封面", trigger: "change" },
        ],
        liveTitle: [
          { required: true, message: "请输入直播标题", trigger: "blur" },
          {
            min: 4,
            max: 15,
            message: "长度在 4 到 15 个字符",
            trigger: "blur",
          },
        ],
      },
      liveTypeList: [
        {
          label: "普通直播",
          value: 0,
        },
        {
          label: "预约直播",
          value: 1,
        },
      ],
    };
  },
  created() {
    this.getMenuPermission();
    this.storeId = storeId.geturlparam();
    this.customId = JSON.parse(
      localStorage.getItem("user_info")
    ).content.customId;
    this.token = JSON.parse(localStorage.getItem("access_token")).content;
    this.myLivePage();
  },
  methods: {
    getMenuPermission() {
      let t = this;
      API.getMenuPermission({
        customId: JSON.parse(localStorage.getItem("user_info")).content
          .customId,
      }).then((res) => {
        if (res.data.code == 0) {
          t.$nextTick(() => {
            if (res.data.data.indexOf("live_create") > -1) {
              t.userType = 30;
            } else {
              t.userType = "";
            }
          });
        }
      });
    },
    search() {
      this.current = 1;
      this.myLivePage();
    },
    // 复制直播
    copyLive(row) {
      let t = this;
      API.copyLive({
        liveId: row.id,
      }).then((res) => {
        console.log(res);
        if (res.data.code == 0) {
          t.myLivePage();
          // t.$emit("getMenuName", { name: "liveSetting", liveId: res.data.data });
        }
      });
    },
    enterLive(row) {
      let t = this;
      console.log(row);
      //获取推流地址
      API.getPushStreamUrl({
        liveId: row.id,
      }).then((res) => {
        if (res.data.code == 0) {
          t.$emit("getMenuName", {
            name: "liveSetting",
            liveId: row.id,
            customId: row.customId,
            pushStreamUrl: res.data.data,
            groupID: row.groupId,
            startTime: row.startTime,
            teacherName: row.name,
          });
        }
      });
    },
    handleAvatarSuccess(res, file) {
      this.form.pictureUrl = res.data;
    },
    beforeAvatarUpload(file) {
      const isJPG = file.type;
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (["image/png", "image/jpeg", "image/jpg"].indexOf(isJPG) == -1) {
        this.$message.error("上传头像图片只能是 PNG JPG JPEG 格式!");
        return;
      }
      if (!isLt2M) {
        this.$message.error("上传头像图片大小不能超过 2MB!");
        return;
      }
      return isJPG && isLt2M;
    },
    // 修改直播
    updateLive(formName) {
      let t = this;
      let duration = "";
      if (this.form.liveType == 1) {
        if (!this.form.appointTime) {
          t.$message.warning("请选择预约时间");
          return;
        }
        if (!this.form.duration) {
          t.$message.warning("请选择持续时间");
          return;
        }
        if (new Date(this.form.appointTime).getTime() <= new Date().getTime()) {
          t.$message.warning("预约时间必须大于当前时间");
          return;
        }
        let durationList = this.form.duration.split(":");
        duration =
          parseInt(durationList[0]) * 60 * 60 +
          parseInt(durationList[1]) * 60 +
          parseInt(durationList[2]);
        if (duration <= 0) {
          t.$message.warning("持续时间必须大于0");
          return;
        }
      }
      this.$refs[formName].validate((valid) => {
        if (valid) {
          API.updateLive({
            ...this.form,
            storeId: this.storeId,
            customId: this.customId,
            appointTime: this.form.liveType == 1 ? this.form.appointTime : null,
            duration: this.form.liveType == 1 ? duration : null,
          }).then((res) => {
            if (res.data.code == 0) {
              t.$message.success("修改成功");
              t.handleClose();
              t.myLivePage();
            }
          });
        } else {
          return false;
        }
      });
    },
    handleClose() {
      this.isEdit = false;
      this.form = {
        id: "",
        pictureUrl: "",
        liveTitle: "",
        liveType: 0,
        remark: "",
      };
    },
    edit(row) {
      this.isEdit = true;
      this.form = {
        id: row.id,
        pictureUrl: row.pictureUrl,
        liveTitle: row.liveTitle,
        liveType: row.liveType,
        remark: row.remark,
        appointTime: row.appointTime,
        duration: row.durationTime,
      };
    },
    toAddLive() {
      this.$emit("getMenuName", { name: "addLive" });
    },
    getLiveId(e) {
      this.liveId = e.liveId;
      if (this.liveId) {
        this.$emit("getMenuName", {
          name: "liveSetting",
          liveId: this.liveId,
          pushStreamUrl: e.pushStreamUrl,
        });
      }
    },
    userManage(row) {
      this.liveId = row.id;
    },
    handleSizeChange(val) {
      this.size = val;
      this.myLivePage();
    },
    handleCurrentChange(val) {
      this.current = val;
      this.myLivePage();
    },
    getTime(num) {
      let ss = num % 60 > 9 ? num % 60 : "0" + (num % 60);

      let hh =
        parseInt(num / 3600) > 9
          ? parseInt(num / 3600)
          : "0" + parseInt(num / 3600);
      let mm =
        parseInt((num - hh * 60 * 60) / 60) > 9
          ? parseInt((num - hh * 60 * 60) / 60)
          : "0" + parseInt((num - hh * 60 * 60) / 60);
      return hh + ":" + mm + ":" + ss;
    },
    myLivePage() {
      let t = this;
      API.myLivePage({
        current: this.current,
        size: this.size,
        storeId: this.storeId,
        liveTitle: this.liveTitle,
        // mobile: "string",
        // name: "string",
        // liveType: 0,
        // status: 0,
        // startDateTimes: ["2023-02-22T03:24:54.116Z"],
        // endDateTimes: ["2023-02-22T03:24:54.116Z"],
        onlyMin: true,
      }).then((res) => {
        if (res.data.code == 0) {
          res.data.data.records.forEach((item) => {
            item.durationTime = t.getTime(parseInt(item.duration));
          });
          t.tableData = res.data.data.records;
          t.current = res.data.data.current;
          t.size = res.data.data.size;
          t.total = res.data.data.total;
        }
      });
    },
  },
};
</script>
    <style lang="scss" scoped>
.addLive_form .el-select {
  width: 100%;
}
.liveList {
  .block {
    display: flex;
    justify-content: center;
  }
  .live_title {
    font-size: 20px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #568abf;
    line-height: 32px;
    padding: 8px 24px;
    border-bottom: 1px solid #e5e5e5;
    display: flex;
    justify-content: space-between;
  }
  .liveList_search {
    margin: 16px 24px;
    .el-input {
      width: 370px;
      margin-left: 24px;
    }
  }
  .myList_data {
    margin: 0 24px;
    overflow: hidden;
    & > div {
      position: relative;
      height: 115px;
      background: #ffffff;
      box-shadow: 2px 2px 8px 0px rgba(0, 0, 0, 0.1);
      border-radius: 6px;
      margin-bottom: 16px;
      padding: 8px 16px;
      box-sizing: border-box;
      & > div {
        margin-right: 24px;
        margin-bottom: 24px;
        display: flex;
        align-items: center;

        div:nth-of-type(1) {
          img {
            width: 171px;
            height: 98px;
            border-radius: 10px;
          }
        }
        & > div:nth-of-type(2) {
          margin-left: 12px;
          div:nth-of-type(1) {
            font-size: 21px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #0f1b41;
            line-height: 32px;
          }
          div:nth-of-type(2),
          div:nth-of-type(3),
          div:nth-of-type(4) {
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #0f1b41;
            line-height: 24px;
          }
        }
        .cz {
          display: flex;
          justify-content: flex-end;
          align-items: center;
          flex: 1;
          div {
            padding: 0 16px;
            height: 26px;
            background: #e2e8f4;
            border-radius: 6px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            font-size: 14px;
            color: #568abf;
            line-height: 26px;
            margin-left: 8px;
            cursor: pointer;
          }
        }
      }
      & > div.status {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 3;
        padding: 0 19px;
        height: 26px;
        line-height: 26px;
        text-align: center;
        box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.25);
        border-radius: 6px 0px 6px 0px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #ffffff;
        text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
      }
      & > div.status.status0 {
        background: #ffbd5a;
      }
      & > div.status.status1 {
        background: #568abf;
      }
      & > div.status.status2 {
        background: #e5e5e5;
        color: #595959;
      }
    }
  }
  .el-date-editor {
    width: 100%;
  }
}
</style>