<template>
  <div class="recommendedProductDetails">
    <div class="warp">
      <div class="title">
        <div>商品信息</div>
        <div @click="back">返回</div>
      </div>
      <div class="product">
        <img :src="productDetail.mainPictureUrl[0]" />
        <div>
          <div class="word2">
            {{ productDetail.productTitle }}
          </div>
          <div>
            <div><span>￥</span>{{ productDetail.specList[0].specPrice }}</div>
            <div>¥{{ productDetail.specList[0].originSpecPrice }}</div>
          </div>
          <div @click="isCode = true">点击扫描二维码购买</div>
        </div>
      </div>
    </div>
    <div class="warp">
      <div class="title">商品信息</div>
      <img
        class="imgMater"
        v-for="item in productDetail.materialPictureUrl"
        :src="item"
      />
    </div>
    <div v-if="isCode" class="code_bg"></div>
    <div v-if="isCode" class="code_warp">
      <img :src="code" />
      <div>微信扫描一下二维码 通过小程序购买该课程</div>
      <img
        class="close"
        @click="isCode = false"
        src="../../images/teacher/close.png"
      />
    </div>
  </div>
</template>
<script>
import MALL from "../../api/mall.js";
export default {
  name: "recommendedProductDetails",
  props: ["productId"],
  data() {
    return {
      productDetail: {},
      code: "",
      isCode: false,
    };
  },
  created() {
    this.product();
    this.wxaCode();
  },
  methods: {
    back() {
      this.$emit("back", "");
    },
    // 获取商品详情
    product() {
      let t = this;
      MALL.product(t.productId).then((res) => {
        if (res.data.code == 0) {
          t.productDetail = res.data.data;
        }
      });
    },
    // 获取商品小程序码
    wxaCode() {
      let t = this;
      MALL.wxaCode(t.productId).then((res) => {
        if (res.data.code == 0) {
          t.code = res.data.data;
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.warp {
  width: 1392px;
  background: #ffffff;
  border-radius: 16px;
  margin: 12px auto;
  padding: 0 90px;
  padding-bottom: 24px;
  box-sizing: border-box;
  .title {
    font-size: 20px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #568abf;
    line-height: 32px;
    padding-top: 12px;
    padding-bottom: 12px;
    border-bottom: 1px solid #e5e5e5;
    margin-bottom: 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    & > div:nth-of-type(2) {
      width: 78px;
      height: 28px;
      background: #ffffff;
      border-radius: 6px;
      border: 1px solid #d8d8d8;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #568abf;
      line-height: 28px;
      text-align: center;
      cursor: pointer;
    }
  }
  .imgMater {
    max-width: 1200px;
  }
  .product {
    display: flex;
    align-items: center;
    justify-content: space-between;
    img {
      width: 344px;
      height: 197px;
      background: #e6eef4;
      border-radius: 10px;
    }
    & > div {
      flex: 1;
      margin-left: 24px;
      & > div:nth-of-type(1) {
        width: 844px;
        height: 64px;
        font-size: 21px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #0f1b41;
        line-height: 32px;
      }
      & > div:nth-of-type(2) {
        display: flex;
        align-items: flex-end;
        & > div:nth-of-type(1) {
          height: 32px;
          font-size: 24px;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #568abf;
          line-height: 32px;
          span {
            font-size: 16px;
          }
        }
        & > div:nth-of-type(2) {
          width: 49px;
          height: 26px;
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #8c8c8c;
          line-height: 26px;
          margin-left: 12px;
          text-decoration: line-through;
        }
      }
      & > div:nth-of-type(3) {
        width: 195px;
        height: 38px;
        background: #568abf;
        border-radius: 8px;
        font-size: 16px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #ffffff;
        line-height: 38px;
        text-align: center;
        margin-top: 24px;
        cursor: pointer;
      }
    }
  }
}
.code_bg {
  position: fixed;
  background: rgba(0, 0, 0, 0.5);
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10;
}
.code_warp {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 12;
  width: 280px;
  height: 304px;
  background: #ffffff;
  border-radius: 16px;
  img {
    width: 200px;
    height: 200px;
    display: block;
    margin: 24px auto 8px;
  }
  div {
    width: 140px;
    height: 48px;
    font-size: 14px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #5f5f5f;
    line-height: 24px;
    margin: 0 auto;
    text-align: center;
  }
  .close {
    width: 32px;
    height: 32px;
    margin: 40px auto;
    cursor: pointer;
  }
}
</style>