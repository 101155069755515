/*
 * @Description: 函数工具
 * @Date: 2022-12-29 11:50:00
 * @LastEditTime: 2022-12-29 11:50:28
 * @FilePath: \massive-web-site\src\utils\util.js
 */

/**
 * 生成随机len位数字
 */
export const randomLenNum = (len, date) => {
  let random = "";
  random = Math.ceil(Math.random() * 100000000000000)
    .toString()
    .substr(0, len || 4);
  if (date) random = random + Date.now();
  return random;
};
