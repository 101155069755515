const storeId = {
    geturlparam() {
        // window.location.href 获取地址
        // let url = window.location.href;
        // let storeId = url.split("study")[1];
        // if (storeId != undefined) {
        //   this.storeId = storeId;
        // } else {
        //   this.$message.info("网址错误");
        //   this.bodyContent = "front";
        //   this.frontContent = "index";
        // }
        let storeId = ''
        let url = window.location.href;
        let arr = url.split("www");
        if (arr.length > 1) {
            storeId = "1584382180499255298"; // 线上
        } else {
            storeId = "1584382180499255298"; // 测试
        }
        return storeId
    }
}
export default storeId