<template>
  <div class="userManagement">
    <el-drawer
      :visible.sync="drawer"
      :direction="direction"
      :before-close="handleClose"
      :with-header="false"
      size="640px"
    >
      <div class="cz">
        <div>用户管理</div>
        <div>
          <div @click="addUser">添加用户</div>
          <div @click="isBatchImport = true">批量导入</div>
          <div @click="download">模板下载</div>
          <div @click="deleteLiveMember">移除</div>
        </div>
      </div>
      <div class="table_warp">
        <el-input
          placeholder="请输入手机号"
          v-model="mobile"
          class="input-with-select"
        >
          <el-select
            v-model="selectLive"
            slot="prepend"
            placeholder="请选择"
            style="width: 120px"
          >
            <el-option label="手机号" value="1"></el-option>
            <el-option label="昵称" value="2"></el-option>
          </el-select>
          <el-button
            slot="append"
            @click="search"
            icon="el-icon-search"
          ></el-button>
        </el-input>
        <div class="table">
          <el-table
            ref="multipleTable"
            :data="tableData"
            tooltip-effect="dark"
            style="width: 100%"
            @selection-change="handleSelectionChange"
          >
            <el-table-column type="selection" width="55"> </el-table-column>
            <el-table-column label="手机号" prop="mobile" width="120">
            </el-table-column>
            <el-table-column prop="name" label="昵称" show-overflow-tooltip>
            </el-table-column>
            <el-table-column prop="role" label="角色" show-overflow-tooltip>
            </el-table-column>
          </el-table>
        </div>
        <div class="block">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="current"
            :page-sizes="[10, 20, 30, 40]"
            :page-size="size"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total"
          >
          </el-pagination>
        </div>
        <div class="flex">
          <el-button @click="handleClose">关 闭</el-button>
          <el-button
            type="primary"
            v-if="nameLive != 'myLive' && liveType != 1"
            @click="enterLive"
            >确定</el-button
          >
        </div>
      </div>
    </el-drawer>
    <el-dialog
      title="添加用户"
      :visible.sync="isAddUser"
      width="640px"
      :before-close="handleClose1"
    >
      <div>
        <el-form :model="form" ref="form" :rules="rules">
          <el-form-item label="手机号" prop="mobile">
            <el-input
              v-model="form.mobile"
              placeholder="请输入手机号"
              maxlength="11"
            ></el-input>
          </el-form-item>
          <el-form-item label="昵称" prop="name">
            <el-input v-model="form.name" placeholder="请输入手机号"></el-input>
          </el-form-item>
          <!-- <el-form-item label="身份">
            <el-input placeholder="请输入手机号"></el-input>
          </el-form-item> -->
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="isAddUser = false">取 消</el-button>
        <el-button type="primary" @click="saveLiveMember('form')"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <el-dialog
      title="批量导入"
      :visible.sync="isBatchImport"
      width="640px"
      :before-close="handleClose1"
    >
      <div>
        <el-upload
          class="upload-demo"
          ref="my-upload"
          action="/api/admin/sys-file/uploadFile"
          :headers="{
            Authorization: 'Bearer ' + token,
          }"
          :on-preview="handlePreview"
          :on-success="handSuccess"
          :on-remove="handleRemove"
          :before-remove="beforeRemove"
          multiple
          :limit="1"
          :on-exceed="handleExceed"
          :file-list="fileList"
        >
          <el-button size="small" type="primary">点击上传</el-button>
          <div slot="tip" class="el-upload__tip">只能上传xlsx文件</div>
        </el-upload>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose1">取 消</el-button>
        <el-button type="primary" @click="uploadLiveMember">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import API from "../../api/live.js";
import storeId from "../../api/storeId.js";
const validPhone = (rule, value, callback) => {
  let reg = /^1[3|4|5|6|7|8|9]\d{9}$/;
  if (!value) {
    callback(new Error("请输入电话号码"));
  } else if (!reg.test(value)) {
    callback(new Error("请输入正确的11位手机号码"));
  } else {
    callback();
  }
};
export default {
  name: "userManagement",
  props: ["liveId", "nameLive", "groupID", "liveType"],
  data() {
    return {
      selectLive: "1",
      storeId: "",
      tableData: [],
      current: 1,
      size: 10,
      total: 0,
      mobile: "",
      name: "",
      multipleSelectionLive: [],

      drawer: false,
      direction: "rtl",

      isAddUser: false,
      form: {
        name: "",
        mobile: "",
      },
      rules: {
        mobile: [{ required: true, validator: validPhone, trigger: "blur" }],
        name: [
          { required: true, message: "请输入昵称", trigger: "blur" },
          {
            min: 2,
            max: 12,
            message: "昵称长度在 2 到 12 个字符",
            trigger: "blur",
          },
        ],
      },

      isBatchImport: false,
      token: "",
      fileList: [],
      fileUrl: null,
    };
  },
  watch: {
    liveId(n, o) {
      if (n) {
        this.drawer = true;
        this.liveMemberPage();
      } else {
        this.drawer = false;
      }
    },
  },
  created() {
    this.storeId = storeId.geturlparam();
    this.token = JSON.parse(localStorage.getItem("access_token")).content;
  },
  methods: {
    search() {
      this.current = 1;
      this.liveMemberPage();
    },
    addUser() {
      this.isAddUser = true;
      this.form.mobile = "";
      this.form.name = "";
      this.$refs["form"].resetFields();
    },
    enterLive() {
      let t = this;
      //获取推流地址
      API.getPushStreamUrl({
        liveId: this.liveId,
      }).then((res) => {
        if (res.data.code == 0) {
          t.$emit("getLiveId", {
            liveId: t.liveId,
            name: "liveSetting",
            customId: JSON.parse(localStorage.getItem("user_info")).content
              .customId,
            pushStreamUrl: res.data.data,
            groupID: t.groupID,
          });
        }
      });
    },
    // 导入直播观看成员
    uploadLiveMember() {
      let t = this;
      if (!this.fileUrl) {
        t.$message.warning("请选择文件");
        return;
      }
      let file = new FormData();
      file.append("file", this.fileUrl);
      API.uploadLiveMember(this.liveId, file).then((res) => {
        if (res.data.code == 0) {
          t.$message.success("导入成功");
          t.isBatchImport = false;
          t.fileList = [];
          t.fileUrl = null;
          this.liveMemberPage();
        }
      });
    },
    handSuccess(res, file) {
      this.fileUrl = file.raw;
    },
    handleRemove(file, fileList) {},
    handlePreview(file) {},
    handleExceed(files, fileList) {
      this.$message.warning(
        `当前限制选择 1 个文件，本次选择了 ${files.length} 个文件，共选择了 ${
          files.length + fileList.length
        } 个文件`
      );
    },
    beforeRemove(file, fileList) {
      return this.$confirm(`确定移除 ${file.name}？`);
    },
    download() {
      this.getBlob(
        "https://file.knhlyd.com:7300/common/451404de-f2ff-4772-9b37-0ab26f15f65d.xlsx",
        "451404de-f2ff-4772-9b37-0ab26f15f65d.xlsx",
        (blob) => {
          this.saveAs(blob, "用户管理模板.xlsx");
        }
      );
    },
    /**
     * 获取 blob
     * @param  {String} url 目标文件地址
     * @param  {String} index 目标文件索引
     * @return {cb}
     */
    getBlob(url, index, cb) {
      var xhr = new XMLHttpRequest();
      xhr.open("GET", url, true);
      xhr.responseType = "blob";
      xhr.onload = function () {
        if (xhr.status === 200) {
          cb(xhr.response);
        }
      };
      xhr.onprogress = (res) => {
        const tempDownload = this.downloadObj[index];
        tempDownload.progress = res.loaded / res.total;
        tempDownload.xhr = xhr;
        this.$set(this.downloadObj, index, tempDownload);
      };
      xhr.onerror = (res) => {
        const tempDownload = this.downloadObj[index];
        tempDownload.error = true;
        this.$set(this.downloadObj, index, tempDownload);
      };
      xhr.send();
    },

    /**
     * 保存
     * @param  {Blob} blob
     * @param  {String} filename 想要保存的文件名称
     */
    saveAs(blob, filename) {
      if (window.navigator.msSaveOrOpenBlob) {
        navigator.msSaveBlob(blob, filename);
      } else {
        var link = document.createElement("a");
        var body = document.querySelector("body");

        link.href = window.URL.createObjectURL(blob);
        link.download = filename;

        // fix Firefox
        link.style.display = "none";
        body.appendChild(link);

        link.click();
        body.removeChild(link);

        window.URL.revokeObjectURL(link.href);
      }
    },
    // 移除用户
    deleteLiveMember() {
      let t = this;
      let arr = [];
      this.multipleSelectionLive.map((item) => {
        arr.push(item.customId);
      });
      if (arr.length == 0) {
        t.$message.warning("请选择要移除的账户");
        return;
      }
      this.$confirm("是否移除?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
      })
        .then(() => {
          API.deleteLiveMember(this.liveId, arr).then((res) => {
            if (res.data.code == 0) {
              t.$message.success("移除成功");
              t.liveMemberPage();
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
    },
    handleSizeChange(val) {
      this.size = val;
      this.liveMemberPage();
    },
    handleCurrentChange(val) {
      this.current = val;
      this.liveMemberPage();
    },
    handleSelectionChange(val) {
      this.multipleSelectionLive = val;
    },
    handleClose(done) {
      let t = this;
      this.$confirm("确认关闭？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
      })
        .then(() => {
          t.drawer = false;
          t.liveId = "";
          t.$emit("getLiveId", { liveId: "", name: "myLive" });
        })
        .catch(() => {});
    },
    handleClose1() {
      this.isAddUser = false;
      this.isBatchImport = false;
      this.form.mobile = "";
      this.form.name = "";
      this.$refs["form"].resetFields();
      this.$refs["my-upload"].clearFiles();
    },
    // 添加直播观看成员
    saveLiveMember(formName) {
      let t = this;
      this.$refs[formName].validate((valid) => {
        if (valid) {
          API.saveLiveMember(this.liveId, {
            storeId: this.storeId,
            name: this.form.name,
            mobile: this.form.mobile,
          }).then((res) => {
            if (res.data.code == 0) {
              t.$message.success("添加成功");
              t.isAddUser = false;
              t.form = {
                name: "",
                mobile: "",
              };
              t.liveMemberPage();
            }
          });
        } else {
          return false;
        }
      });
    },
    // 获取直播参与成员列表
    liveMemberPage() {
      let t = this;
      if (!t.liveId) {
        return;
      }
      API.liveMemberPage({
        current: this.current,
        size: this.size,
        storeId: this.storeId,
        liveId: this.liveId,
        mobile: this.selectLive == 1 ? this.mobile : null,
        name: this.selectLive == 2 ? this.mobile : null,
        // searcherValue: "string",
      }).then((res) => {
        if (res.data.code == 0) {
          t.tableData = res.data.data.records;
          t.current = res.data.data.current;
          t.size = res.data.data.size;
          t.total = res.data.data.total;
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.el-dialog {
  z-index: 2015 !important;
}

.userManagement {
  .cz {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 24px 70px;
    border-bottom: 1px solid #e5e5e5;
    & > div:nth-of-type(1) {
      width: 96px;
      height: 32px;
      font-size: 24px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #568abf;
      line-height: 32px;
    }
    & > div:nth-of-type(2) {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      flex: 1;
      div {
        height: 28px;
        border-radius: 6px;
        border: 1px solid #568abf;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #568abf;
        line-height: 28px;
        padding: 0 12px;
        margin-left: 12px;
        cursor: pointer;
      }
      div:nth-last-of-type(1) {
        border: 1px solid #f55050;
        color: #f55050;
      }
    }
  }
  .table_warp {
    padding: 24px 70px;
    .table {
      margin-top: 20px;
    }
    .block,
    .flex {
      display: flex;
      justify-content: center;
      margin-top: 20px;
      .el-button {
        width: 100px;
      }
    }
  }
}
</style>