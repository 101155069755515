<template>
  <div class="liveSetting">
    <!-- <div id="remotetracks"></div> -->

    <div class="warp">
      <div id="localtracks_warp">
        <!-- <div class="name">
          <img src="../../images/mkf2.png" />
          <div>{{ teacherName }}</div>
        </div> -->
        <video
          v-if="userCustomId != customId"
          id="player-container-id"
          width="414"
          height="270"
          preload="auto"
          playsinline
          webkit-playsinline
        ></video>
        <div id="localtracks" v-if="userCustomId == customId"></div>
        <!-- <div class="toolbar" v-if="userCustomId == customId">
          <el-tooltip
            class="item"
            effect="dark"
            content="撤销"
            placement="top-start"
          >
            <img src="../../images/icon/1.png" @click="undo" />
          </el-tooltip>
          <el-tooltip
            class="item"
            effect="dark"
            content="恢复"
            placement="top-start"
          >
            <img src="../../images/icon/2.png" @click="redo" />
          </el-tooltip>
          <el-tooltip
            class="item"
            effect="dark"
            content="画笔"
            placement="top-start"
          >
            <img src="../../images/icon/3.png" @click="paintbrush" />
          </el-tooltip>
          <el-tooltip
            class="item"
            effect="dark"
            content="橡皮擦"
            placement="top-start"
          >
            <img src="../../images/icon/4.png" @click="eraser" />
          </el-tooltip>
          <el-tooltip
            class="item"
            effect="dark"
            content="文本"
            placement="top-start"
          >
            <img src="../../images/icon/5.png" @click="text" />
          </el-tooltip>
          <el-tooltip
            class="item"
            effect="dark"
            content="文件列表"
            placement="top-start"
          >
            <img src="../../images/icon/6.png" @click="getFileInfoList" />
          </el-tooltip>
          <el-tooltip
            class="item"
            effect="dark"
            content="箭头"
            placement="top-start"
          >
            <img src="../../images/icon/7.png" @click="arrow" />
          </el-tooltip>
          <el-tooltip
            class="item"
            effect="dark"
            content="资源"
            placement="top-start"
          >
            <img src="../../images/icon/8.png" />
          </el-tooltip>
          <el-tooltip
            class="item"
            effect="dark"
            content="清空白板"
            placement="top-start"
          >
            <img src="../../images/icon/9.png" @click="reset" />
          </el-tooltip>
          <el-tooltip
            class="item"
            effect="dark"
            content="上一页"
            placement="top-start"
          >
            <img src="../../images/icon/8.png" @click="prevBoard" />
          </el-tooltip>
          <el-tooltip
            class="item"
            effect="dark"
            content="下一页"
            placement="top-start"
          >
            <img src="../../images/icon/8.png" @click="nextBoard" />
          </el-tooltip>
          <el-tooltip
            class="item"
            effect="dark"
            content="缩小"
            placement="top-start"
          >
            <img src="../../images/icon/8.png" @click="shrink" />
          </el-tooltip>
          <span>{{ scale }}%</span>

          <el-tooltip
            class="item"
            effect="dark"
            content="放大"
            placement="top-start"
          >
            <img src="../../images/icon/8.png" @click="enlarge" />
          </el-tooltip>

          <el-tooltip
            class="item"
            effect="dark"
            content="新建白板"
            placement="top-start"
          >
            <img src="../../images/icon/10.png" @click="addBoard" />
          </el-tooltip>
        </div> -->
      </div>
      <div class="cz_warp">
        <div class="del" @click="closeLive" v-if="userCustomId == customId">
          结束直播 {{ liveTime }}
        </div>
        <div class="del" @click="closeLive" v-if="userCustomId != customId">
          退出直播
        </div>
        <div class="live_options" v-if="customId == userCustomId && isShow">
          <div @click="tagMicrophone" :class="isMicrophone ? '' : 'act'">
            <div>
              <img v-if="isMicrophone" src="../../images/mkf.png" />
              <img v-if="!isMicrophone" src="../../images/mkf1.png" />
            </div>
            <div>麦克风</div>
            <div v-if="userCustomId != customId" class="del"></div>
          </div>
          <div @click="tagCamera" :class="isCamera ? '' : 'act'">
            <div>
              <img v-if="isCamera" src="../../images/sp.png" />
              <img v-if="!isCamera" src="../../images/sp1.png" />
            </div>
            <div>摄像头</div>
            <div v-if="userCustomId != customId" class="del"></div>
          </div>
          <div
            @click="tagScreenSharing"
            :class="isPM ? '' : 'act'"
            v-if="userCustomId == customId"
          >
            <div>
              <img v-if="isPM" src="../../images/pm.png" />
              <img v-if="!isPM" src="../../images/pm1.png" />
            </div>
            <div>共享屏幕</div>
          </div>
        </div>
        <div
          class="display"
          v-if="customId == userCustomId"
          @click="isShow = !isShow"
        >
          <img v-if="isShow" src="../../images/top.png" />
          <img v-else src="../../images/bottom.png" />
        </div>
        <div class="list_warp">
          <div class="list">
            <div :class="tagFlag ? 'active' : ''" @click="tagMessage(true)">
              聊天室
              <div></div>
            </div>
            <div
              v-if="livePermission.length > 0"
              :class="!tagFlag ? 'active' : ''"
              @click="tagMessage(false)"
            >
              群成员
              <div></div>
            </div>
          </div>
          <div class="user_list" v-show="!tagFlag">
            <div
              class="userList_warp"
              :style="isShow ? 'height: 320px;' : 'height: 437px'"
            >
              <div v-for="(item, index) in groupMaster" class="user_warp">
                <div class="user">
                  <div>{{ item.name.slice(0, 1) }}</div>
                  <div class="zc">主持</div>
                  <div>{{ item.name }}</div>
                </div>
              </div>
              <div v-for="(item, index) in groupMemberList" class="user_warp">
                <div class="user">
                  <div>{{ item.name.slice(0, 1) }}</div>
                  <div>
                    <div v-if="item.coPresenterFlag" class="lx">管理</div>
                    <div
                      class="name"
                      :style="
                        item.coPresenterFlag ? 'width: 95px' : 'width: 140px'
                      "
                    >
                      {{ item.name }}
                    </div>
                  </div>
                </div>
                <div>
                  <div
                    v-if="
                      item.muteUntil > 0 &&
                      livePermission.indexOf('Estoppel') > -1
                    "
                    class="jy"
                    @click="setGroupMemberMuteTime([item.userID], 0, item, '')"
                  >
                    <img src="../../images/jy1.png" />
                  </div>
                  <div
                    v-if="
                      livePermission.indexOf('Estoppel') > -1 &&
                      item.muteUntil == 0
                    "
                    class="jy"
                    @click="setGroupMemberMuteTime([item.userID], 1, item, '')"
                  >
                    <img src="../../images/jy.png" />
                  </div>
                  <el-dropdown
                    v-if="
                      livePermission.indexOf('setJointHost') > -1 ||
                      livePermission.indexOf('kickOut') > -1
                    "
                  >
                    <span class="el-dropdown-link">
                      <img src="../../images/more.png" />
                    </span>
                    <el-dropdown-menu slot="dropdown">
                      <!-- <el-dropdown-item>
                      <div
                        type="text"
                        size="mini"
                        v-if="
                          livePermission.indexOf('Estoppel') > -1 &&
                          item.muteUntil == 0
                        "
                        @click="setGroupMemberMuteTime([item.userID], 1, item)"
                      >
                        禁言
                      </div>
                    </el-dropdown-item>
                    <el-dropdown-item>
                      <div
                        type="text"
                        size="mini"
                        v-if="
                          livePermission.indexOf('Estoppel') > -1 &&
                          item.muteUntil > 0
                        "
                        @click="setGroupMemberMuteTime([item.userID], 0, item)"
                      >
                        解除禁言
                      </div>
                    </el-dropdown-item> -->
                      <el-dropdown-item>
                        <div
                          type="text"
                          size="mini"
                          v-if="
                            livePermission.indexOf('setJointHost') > -1 &&
                            !item.coPresenterFlag
                          "
                          @click="setGroupMemberRole(item)"
                        >
                          设为联席主持人
                        </div>
                      </el-dropdown-item>
                      <el-dropdown-item>
                        <div
                          type="text"
                          size="mini"
                          v-if="
                            livePermission.indexOf('setJointHost') > -1 &&
                            item.coPresenterFlag
                          "
                          @click="rescGroupMemberRole(item)"
                        >
                          解除联席主持人
                        </div>
                      </el-dropdown-item>
                      <el-dropdown-item>
                        <div
                          size="mini"
                          type="text"
                          v-if="livePermission.indexOf('kickOut') > -1"
                          @click="deleteGroupMember(item)"
                        >
                          踢出直播间
                        </div>
                      </el-dropdown-item>
                    </el-dropdown-menu>
                  </el-dropdown>
                </div>
              </div>
            </div>
            <div class="send_user_warp">
              <el-button
                :disabled="livePermission.indexOf('Invite') > -1 ? false : true"
                @click="inviteClick"
                >邀请</el-button
              >
              <el-button
                :disabled="
                  livePermission.indexOf('Estoppel') > -1 ? false : true
                "
                @click="allSetGroupMemberMuteTime"
                >{{ allFlag ? "全部禁言" : "解除禁言" }}</el-button
              >
            </div>
          </div>
          <div class="message_list" v-show="tagFlag">
            <div
              class="message_warp"
              :style="
                isShow
                  ? 'height: ' + (customId == userCustomId ? 285 : 325) + 'px;'
                  : 'height: ' + (customId == userCustomId ? 402 : 442) + 'px'
              "
            >
              <div class="message_scroll">
                <div
                  v-for="(item, index) in messageList"
                  :class="
                    item.userID == userCustomId ? 'right_warp' : 'left_warp'
                  "
                >
                  <div v-if="item.text.indexOf('-**-') > -1" class="tz">
                    管理员将【{{ item.text.split("-**-")[0] }}】{{
                      item.text.split("-**-")[1]
                    }}
                  </div>
                  <div
                    v-if="
                      item.userID != userCustomId &&
                      item.text.indexOf('-*-') > -1
                    "
                    class="tx"
                  >
                    {{ item.text.split("-*-")[1].slice(0, 1) }}
                  </div>

                  <div
                    v-if="item.text.indexOf('-*-') > -1"
                    :class="item.userID == userCustomId ? 'right' : 'left'"
                  >
                    <span
                      style="color: #8c8c8c"
                      v-if="item.userID != userCustomId"
                      >{{ item.text.split("-*-")[1] }}：</span
                    >{{ item.text.split("-*-")[0] }}
                  </div>
                  <div
                    v-if="
                      item.userID == userCustomId &&
                      item.text.indexOf('-*-') > -1
                    "
                    class="tx"
                  >
                    {{ item.text.split("-*-")[1].slice(0, 1) }}
                  </div>
                </div>
              </div>
            </div>
            <div class="send_warp">
              <el-input
                v-model="message"
                :placeholder="isMuteTime ? '禁言中' : '请输入'"
                :disabled="isMuteTime"
                maxlength="200"
                type="textarea"
                show-word-limit
                resize="none"
                :rows="3"
              ></el-input>
              <el-button
                type="text"
                @click="sendMessage('-*-', '', '')"
                :disabled="isMuteTime"
                >发送</el-button
              >
            </div>
          </div>
        </div>
        <!-- <div
          @click="whiteboard"
          :class="isPM ? '' : 'act'"
          v-if="userCustomId == customId"
        >
          <img v-if="isPM" src="../../images/pm.png" />
          <img v-if="!isPM" src="../../images/pm1.png" />
          <div>白板</div>
        </div> -->
      </div>
    </div>

    <!-- <div @click="logoutIM">logoutIM</div> -->
    <el-dialog :visible.sync="isExitLive" width="640px" :show-close="false">
      <span class="exitLive">是否离开直播间</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="isExitLive = false">取 消</el-button>
        <el-button type="primary" @click="toLiveList">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog
      title="添加用户"
      :visible.sync="isInvite"
      width="640px"
      :before-close="handleClose1"
    >
      <div>
        <el-form :model="form" ref="form" :rules="rules">
          <el-form-item label="手机号" prop="mobile">
            <el-input
              v-model="form.mobile"
              placeholder="请输入手机号"
              maxlength="11"
            ></el-input>
          </el-form-item>
          <el-form-item label="昵称" prop="name">
            <el-input v-model="form.name" placeholder="请输入手机号"></el-input>
          </el-form-item>
          <!-- <el-form-item label="身份">
            <el-input placeholder="请输入手机号"></el-input>
          </el-form-item> -->
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose1">取 消</el-button>
        <el-button type="primary" @click="saveLiveMember('form')"
          >确 定</el-button
        >
      </span>
    </el-dialog>

    <div v-if="KickOutLive" class="endLive_warp">
      <div class="endLive_warp_tc">
        <div class="endLive">
          <img src="../../images/js.png" />
          <div class="live_title">你已被移出直播间！</div>
          <!-- <div class="live_time">直播时长：{{ liveTime }}</div> -->
        </div>
        <div class="close" @click="closeLiveTc">关闭窗口</div>
      </div>
    </div>
    <div v-if="isEndLive" class="endLive_warp">
      <div class="endLive_warp_tc">
        <div class="endLive">
          <img src="../../images/js.png" />
          <div class="live_title">您已成功结束直播</div>
          <div class="live_time">直播时长：{{ liveTime }}</div>
        </div>
        <div class="close" @click="closeLiveTc">关闭窗口</div>
      </div>
    </div>
  </div>
</template>

<script>
import API from "../../api/live.js";

import storeId from "../../api/storeId.js";
import QNRTC from "qnweb-rtc";

import TIM from "tim-js-sdk";
// import TIM from 'tim-wx-sdk'; // 微信小程序环境请取消本行注释，并注释掉 import TIM from 'tim-js-sdk';
import TIMUploadPlugin from "tim-upload-plugin"; // 使用前请将 IM SDK 升级到v2.9.2或更高版本
import TIMProfanityFilterPlugin from "tim-profanity-filter-plugin"; // 使用前请将 IM SDK 升级到v2.24.0或更高版本
// 创建 SDK 实例，TIM.create() 方法对于同一个 SDKAppID 只会返回同一份实例
let options = {
  SDKAppID: 1400795746, // 接入时需要将0替换为您的即时通信应用的 SDKAppID
};
let tim = TIM.create(options); // SDK 实例通常用 tim 表示

let teduBoard = null;

let livePusher = null;
let videoEffectManager = null;
let timer = null;
const validPhone = (rule, value, callback) => {
  let reg = /^1[3|4|5|6|7|8|9]\d{9}$/;
  if (!value) {
    callback(new Error("请输入电话号码"));
  } else if (!reg.test(value)) {
    callback(new Error("请输入正确的11位手机号码"));
  } else {
    callback();
  }
};
export default {
  name: "liveSetting",
  props: [
    "liveId",
    "customId",
    "pushStreamUrl",
    "groupID",
    "startTime",
    "teacherName",
  ],
  data() {
    return {
      userName: "",
      tagFlag: true,
      isMuteTime: false,
      groupMemberList: [],
      groupMaster: [],
      messageList: [],
      message: "",
      nextReqMessageID: "",
      isExitLive: false,
      isEndLive: false,
      userCustomId: "",
      publishUrl: "",
      roomToken: "",
      isCamera: false,
      isMicrophone: false,
      payloadText: "",
      isShow: true,
      isPM: false,
      liveTime: "00:00:00",
      storeId: "",
      userSig: "",
      size: 1000,
      KickOutLive: false,
      userSigBb: "",
      screenStreamId: [],
      scale: 100,
      fileList: [],
      livePermission: [],
      isInvite: false,
      form: {
        name: "",
        mobile: "",
      },
      rules: {
        mobile: [{ required: true, validator: validPhone, trigger: "blur" }],
        name: [
          { required: true, message: "请输入昵称", trigger: "blur" },
          {
            min: 2,
            max: 12,
            message: "昵称长度在 2 到 12 个字符",
            trigger: "blur",
          },
        ],
      },
      allFlag: true,
    };
  },
  async created() {
    let t = this;
    this.storeId = storeId.geturlparam();
    this.userCustomId = JSON.parse(
      localStorage.getItem("user_info")
    ).content.customId;
    if (t.customId != t.userCustomId) {
      t.isShow = false;
    }
    let script = document.createElement("script");
    script.type = "text/javascript";
    script.src =
      "https://video.sdk.qcloudecdn.com/web/TXLivePusher-2.0.3.min.js";
    document.body.appendChild(script);
    script.onload = function () {
      setTimeout(() => {
        // t.getLivePermission();
        t.init(t.pushStreamUrl);
      }, 500);
    };

    // await this.getRoomToken();
    // this.getSetting();
    // await this.getPublishUrl() ;
  },
  methods: {
    // 添加直播观看成员
    saveLiveMember(formName) {
      let t = this;
      this.$refs[formName].validate((valid) => {
        if (valid) {
          API.saveLiveMember(this.liveId, {
            storeId: this.storeId,
            name: this.form.name,
            mobile: this.form.mobile,
          }).then((res) => {
            if (res.data.code == 0) {
              t.$message.success("添加成功");
              t.isAddUser = false;
              t.sendMessage("-**-", "加入了群聊", t.form.name);
              t.getGroupMemberList();
              t.handleClose1();
              t.form = {
                name: "",
                mobile: "",
              };
            }
          });
        } else {
          return false;
        }
      });
    },
    tagMessage(tagFlag) {
      this.tagFlag = tagFlag;
    },
    inviteClick() {
      this.isInvite = true;
    },
    handleClose1() {
      this.isInvite = false;
      this.form.mobile = "";
      this.form.name = "";
      this.$refs["form"].resetFields();
    },
    // --------------------------------------------------------------------
    whiteboard() {
      livePusher.stopCamera();
      livePusher.pauseVideo();
      this.getBbUserSig();
    },
    getFileInfoList() {
      this.fileList = teduBoard.getFileInfoList();
      console.log(this.fileList);
    },
    arrow() {
      teduBoard.getToolType(
        TEduBoard.TEduBoardToolType.TEDU_BOARD_TOOL_TYPE_MOUSE
      );
      teduBoard.setToolType(
        TEduBoard.TEduBoardToolType.TEDU_BOARD_TOOL_TYPE_MOUSE
      );
    },
    enlarge() {
      this.scale += 100;
      if (this.scale > 300) {
        this.scale = 300;
        return;
      }
      teduBoard.setBoardScale(this.scale);
    },
    shrink() {
      this.scale -= 100;
      if (this.scale < 100) {
        this.scale = 100;
        return;
      }
      teduBoard.setBoardScale(this.scale);
    },
    prevBoard() {
      teduBoard.prevBoard();
    },
    nextBoard() {
      teduBoard.nextBoard();
    },
    addBoard() {
      teduBoard.addBoard();
    },
    undo() {
      teduBoard.undo();
    },
    redo() {
      teduBoard.redo();
    },
    reset() {
      teduBoard.reset();
    },
    text() {
      teduBoard.getToolType(
        TEduBoard.TEduBoardToolType.TEDU_BOARD_TOOL_TYPE_TEXT
      );
      teduBoard.setToolType(
        TEduBoard.TEduBoardToolType.TEDU_BOARD_TOOL_TYPE_TEXT
      );
      teduBoard.setNextTextInput("互动白板");
    },
    paintbrush() {
      teduBoard.getToolType(
        TEduBoard.TEduBoardToolType.TEDU_BOARD_TOOL_TYPE_PEN
      );
      teduBoard.setToolType(
        TEduBoard.TEduBoardToolType.TEDU_BOARD_TOOL_TYPE_PEN
      );
    },
    eraser() {
      teduBoard.setCursorIcon(
        TEduBoard.TEduBoardToolType.TEDU_BOARD_TOOL_TYPE_ERASER,
        {
          cursor: "url",
          url: "https://res.qcloudtiw.com/board/icons/eraser.png",
          offsetX: 0,
          offsetY: 0,
        }
      );
      teduBoard.getToolType(
        TEduBoard.TEduBoardToolType.TEDU_BOARD_TOOL_TYPE_ERASER
      );
      teduBoard.setToolType(
        TEduBoard.TEduBoardToolType.TEDU_BOARD_TOOL_TYPE_ERASER
      );
    },
    // startWhiteboardPush() {
    //   let t = this;
    //   API.startWhiteboardPush({
    //     liveId: t.liveId,
    //   }).then((res) => {
    //     if (res.data.code == 0) {
    //     }
    //   });
    // },
    // stopWhiteboardPush() {
    //   let t = this;
    //   API.stopWhiteboardPush({
    //     liveId: t.liveId,
    //   }).then((res) => {
    //     if (res.data.code == 0) {
    //     }
    //   });
    // },
    // initTeduBoard() {
    //   let t = this;
    //   console.log("白板");
    //   let initParams = {
    //     id: "localtracks", // dom节点id
    //     classId: t.groupID, // 课堂 ID，32位整型，取值范围[1, 4294967294]
    //     sdkAppId: options.SDKAppID, // 整数
    //     userId: t.liveId, // 字符串
    //     userSig: t.userSigBb, // 字符串
    //   };
    //   teduBoard = new TEduBoard(initParams);
    //   t.boardList = teduBoard.getBoardList();
    //   teduBoard.setBoardScale(t.scale);
    //   t.startWhiteboardPush();
    //   teduBoard.on(TEduBoard.EVENT.TEB_GOTOSTEP, (currentStep, totalStep) => {
    //     console.log(
    //       "======================:  ",
    //       "TEB_GOTOSTEP",
    //       currentStep,
    //       totalStep
    //     );
    //   });
    //   teduBoard.on(TEduBoard.EVENT.TEB_SYNCDATA, (data) => {
    //     console.log("======================:  ", "TEB_SYNCDATA", data);
    //     teduBoard.addSyncData(data);
    //     teduBoard.setDataSyncEnable(true);
    //     teduBoard.isDataSyncEnable(true);
    //     t.boardList = teduBoard.getBoardList();
    //     console.log(t.boardList);
    //   });
    //   teduBoard.setDataSyncEnable(true);
    //   teduBoard.isDataSyncEnable(true);
    //   // 监听白板错误事件
    //   // teduBoard.on(TEduBoard.EVENT.TEB_ERROR, (code, msg) => {
    //   //   console.log("监听白板错误事件", code, msg);
    //   // });
    //   // // 监听白板告警事件
    //   // teduBoard.on(TEduBoard.EVENT.TEB_WARNING, (code, msg) => {
    //   //   console.log("监听白板告警事件", code, msg);
    //   // });
    //   // 1. 监听操作白板参数的数据，并将回调的数据通过 im 发送到接收者
    //   teduBoard.on(TEduBoard.EVENT.TEB_SYNCDATA, (data) => {
    //     const message = tim.createCustomMessage({
    //       to: t.groupID,
    //       conversationType: TIM.TYPES.CONV_GROUP,
    //       priority: TIM.TYPES.MSG_PRIORITY_HIGH, // 因为im消息有限频，白板消息的优先级调整为最高
    //       payload: {
    //         data: JSON.stringify(data),
    //         description: "",
    //         extension: "TXWhiteBoardExt",
    //       },
    //     });
    //     // 发送消息
    //     tim.sendMessage(message).then(
    //       () => {
    //         // 发送成功
    //         teduBoard.addAckData(data);
    //         //信令发送成功后调用 addAckData(data)，确认数据发送状态
    //       },
    //       (error) => {
    //         // 发送失败，建议进行重试
    //         console.log("发送失败，建议进行重试", error);
    //       }
    //     );
    //   });
    // },
    // --------------------------------------------------------------------------------
    to_footer() {
      let div = document.getElementsByClassName("message_warp")[0];
      let divScroll = document.getElementsByClassName("message_scroll")[0];
      console.log(divScroll.scrollHeight);
      div.scrollTop = divScroll.scrollHeight + 2000;
    },

    //设为联席主持人
    setCoHost(row) {
      let t = this;
      API.setCoHost({
        liveId: this.liveId,
        customId: row.userID,
      }).then((res) => {
        if (res.data.code == 0) {
          t.$message.success("设为联席主持人成功");
          t.sendMessage("-**-", "设为联席主持人", row.name);
          t.getGroupMemberList();
        }
      });
    },
    // 解除联席主持人
    rescindCoHost(row) {
      let t = this;
      API.rescindCoHost({
        liveId: this.liveId,
        customId: row.userID,
      }).then((res) => {
        if (res.data.code == 0) {
          t.$message.success("解除联席主持人成功");
          t.sendMessage("-**-", "解除联席主持人", row.name);
          t.getGroupMemberList();
        }
      });
    },
    rescGroupMemberRole(row) {
      let t = this;
      // let promise = tim.setGroupMemberRole({
      //   groupID: this.groupID,
      //   userID: row.userID,
      //   role: TIM.TYPES.GRP_MBR_ROLE_MEMBER, // 将群 ID: group1 中的用户：user1 设为管理员 可选值：TIM.TYPES.GRP_MBR_ROLE_ADMIN（群管理员）,TIM.TYPES.GRP_MBR_ROLE_MEMBER（群普通成员）,TIM.TYPES.GRP_MBR_ROLE_CUSTOM（自定义群成员角色，仅社群支持）
      // });
      // promise
      //   .then(function (imResponse) {
      //     // console.log(imResponse.data.group); // 修改后的群资料
      //     // console.log(imResponse.data.member); // 修改后的群成员资料

      this.$confirm("此操作将该用户解除联席主持人, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          t.rescindCoHost(row);
        })
        .catch(() => {});
      // })
      // .catch(function (imError) {
      //   console.warn("setGroupMemberRole error:", imError); // 错误信息
      // });
    },
    //设置群管理员
    setGroupMemberRole(row) {
      let t = this;

      this.$confirm("此操作将该用户设为联席主持人, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          t.setCoHost(row);
        })
        .catch(() => {});
    },
    allSetGroupMemberMuteTime() {
      let list = [];
      this.groupMemberList.forEach((item) => {
        list.push(item.userID);
      });
      if (this.allFlag) {
        this.setGroupMemberMuteTime(list, 2, { name: "所有人员" }, "allFlag");
      } else {
        this.setGroupMemberMuteTime(list, 0, { name: "所有人员" }, "allFlag");
      }
    },
    // 设置群成员的禁言时间
    setGroupMemberMuteTime(list, time, row, tag) {
      let t = this;
      if (time > 0) {
        this.$confirm("是否禁言?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            API.setBan({
              liveId: t.liveId,
              customIds: [...list],
            }).then((res) => {
              if (res.data.code == 0) {
                t.getGroupMemberList();
                t.sendMessage("-**-", "禁言", row.name);
                if (tag == "allFlag") {
                  t.allFlag = false;
                }
              }
            });
          })
          .catch(() => {});
      } else {
        this.$confirm("是否解除禁言?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            API.rescindBan({
              liveId: t.liveId,
              customIds: [...list],
            }).then((res) => {
              if (res.data.code == 0) {
                t.getGroupMemberList();
                t.sendMessage("-**-", "解除禁言", row.name);
                if (tag == "allFlag") {
                  t.allFlag = true;
                }
              }
            });
          })
          .catch(() => {});
      }
    },
    seachEnter(e) {
      var keyCode = window.event ? e.keyCode : e.which;
      if (keyCode == 13) {
        this.sendMessage("-*-", t.userName, t.message);
      }
    },
    // 发送群消息
    sendMessage(tag, val, name) {
      let t = this;
      let tagName = "";
      let tagMessage = "";
      if (val) {
        tagName = val;
        tagMessage = name;
      } else {
        tagName = t.userName;
        tagMessage = t.message;
      }
      if (!tagMessage) {
        t.$message.warning("请输入需要发送的内容");
        return;
      }
      let message = tim.createTextMessage({
        to: this.groupID,
        conversationType: TIM.TYPES.CONV_GROUP,
        payload: {
          text: tagMessage + tag + tagName,
        },
      });
      // 发送消息
      let promise = tim.sendMessage(message);
      promise
        .then(function (imResponse) {
          // 发送成功
          console.log("发送成功", imResponse);
          t.message = "";
        })
        .catch(function (imError) {
          // 发送失败
          console.warn("sendMessage error:", imError);
        });
    },
    async initIM() {
      let t = this;
      let onSdkReady = function (event) {
        setTimeout(() => {}, 500);
      };
      t.$nextTick(() => {
        this.messageList = [];
      });
      tim.on(TIM.EVENT.SDK_READY, onSdkReady);
      tim.setLogLevel(0);
      tim.registerPlugin({ "tim-upload-plugin": TIMUploadPlugin });

      // 注册腾讯云即时通信 IM 本地审核插件
      tim.registerPlugin({
        "tim-profanity-filter-plugin": TIMProfanityFilterPlugin,
      });
      // 监听事件，如：
      tim.on(TIM.EVENT.SDK_READY, function (event) {
        console.log(
          "收到离线消息和会话列表同步完毕通知，接入侧可以调用 sendMessage 等需要鉴权的接口",
          event
        );
        // event.name - TIM.EVENT.SDK_READY
      });
      // 2. 接收者通过监听 im 消息回调，将收到的数据添加到白板中
      // tim.on(TIM.EVENT.MESSAGE_RECEIVED, function (event) {
      //   console.log(
      //     "收到推送的单聊、群聊、群提示、群系统通知的新消息，可通过遍历 event.data 获取消息列表数据并渲染到页面",
      //     event
      //   );
      //   const messages = event.data;
      //   const groupId = String(this.classInfo.classId);
      //   messages.forEach((message) => {
      //     // 群组消息
      //     if (message.conversationType === TIM.TYPES.CONV_GROUP) {
      //       if (message.to === groupId) {
      //         // 如果是当前群组
      //         const elements = message.getElements();
      //         if (elements.length) {
      //           elements.forEach(async (element) => {
      //             if (element.type === "TIMCustomElem") {
      //               // 自定义消息
      //               if (element.content.extension === "TXWhiteBoardExt") {
      //                 // 是白板的自定义消息
      //                 if (message.from != t.liveId) {
      //                   // 并且发消息的人不是自己
      //                   // 将白板信令设置给白板
      //                   teduBoard.addSyncData(data);
      //                 }
      //               }
      //             }
      //           });
      //         }
      //       } else {
      //         // 其他群组消息自行处理，在互动白板的场景中可以忽略其他群组的消息
      //       }
      //     } else if (message.conversationType === TIM.TYPES.CONV_C2C) {
      //       // C2C消息
      //       // c2c消息在互动白板的场景中可以直接忽略
      //     }
      //   });
      //   // event.name - TIM.EVENT.MESSAGE_RECEIVED
      //   // event.data - 存储 Message 对象的数组 - [Message]
      // });

      // tim.on(TIM.EVENT.MESSAGE_MODIFIED, function (event) {
      //   console.log(
      //     "收到消息被第三方回调修改的通知，消息发送方可通过遍历 event.data 获取消息列表数据并更新页面上同 ID 消息的内容（v2.12.1起支持）",
      //     event
      //   );
      // });

      // tim.on(TIM.EVENT.MESSAGE_REVOKED, function (event) {
      //   console.log(
      //     "收到消息被撤回的通知。使用前需要将SDK版本升级至v2.4.0或更高版本",
      //     event
      //   );
      // });

      // tim.on(TIM.EVENT.MESSAGE_READ_BY_PEER, function (event) {
      //   console.log(
      //     "SDK 收到对端已读消息的通知，即已读回执。使用前需要将SDK版本升级至v2.7.0或更高版本。仅支持单聊会话",
      //     event
      //   );
      // });

      // tim.on(TIM.EVENT.CONVERSATION_LIST_UPDATED, function (event) {
      //   console.log(
      //     "收到会话列表更新通知，可通过遍历 event.data 获取会话列表数据并渲染到页面",
      //     event,
      //     t.groupID
      //   );
      //   t.$nextTick(() => {
      //     if (event.data.length == 1) {
      //       t.messageList = [];
      //     }
      //     event.data.forEach((item) => {
      //       if (item.groupProfile) {
      //         if (t.groupID == item.groupProfile.groupID) {
      //           if (localStorage.getItem("lastTime")) {
      //             t.getLivePermission();
      //             t.getGroupMemberList();
      //           }
      //           localStorage.setItem("lastTime", item.lastMessage.lastTime);
      //           let list = [];
      //           if (t.messageList.length == 0) {
      //             t.messageList.push({
      //               text: item.lastMessage.payload.text,
      //               time: item.lastMessage.lastTime,
      //               userID: item.lastMessage.fromAccount,
      //               name: item.groupProfile.name,
      //             });
      //           } else if (
      //             t.messageList[t.messageList.length - 1].time !=
      //             item.lastMessage.lastTime
      //           ) {
      //             list.push({
      //               text: item.lastMessage.payload.text,
      //               time: item.lastMessage.lastTime,
      //               userID: item.lastMessage.fromAccount,
      //               name: item.groupProfile.name,
      //             });
      //             t.messageList = [...t.messageList, ...list];
      //           }
      //           setTimeout(() => {
      //             t.to_footer();
      //           }, 500);
      //         }
      //       }
      //     });
      //   });
      // });

      tim.on(TIM.EVENT.GROUP_LIST_UPDATED, function (event) {
        console.log(
          "收到群组列表更新通知，可通过遍历 event.data 获取群组列表数据并渲染到页面",
          event,
          t.groupID
        );
        t.$nextTick(() => {
          // if (event.data.length == 1) {
          //   t.messageList = [];
          // }
          event.data.forEach((item) => {
            // if (item.groupProfile) {
            if (t.groupID == item.groupID) {
              // if (localStorage.getItem("lastTime")) {
              t.getLivePermission();
              t.getGroupMemberList();
              // }
              // localStorage.setItem("lastTime", item.lastMessage.lastTime);
              let list = [];
              if (t.messageList.length == 0) {
                t.messageList.push({
                  text: item.lastMessage.payload.text,
                  time: item.lastMessage.lastTime,
                  userID: item.lastMessage.fromAccount,
                  name: item.name,
                });
              } else if (
                t.messageList[t.messageList.length - 1].time !=
                item.lastMessage.lastTime
              ) {
                list.push({
                  text: item.lastMessage.payload.text,
                  time: item.lastMessage.lastTime,
                  userID: item.lastMessage.fromAccount,
                  name: item.name,
                });
                t.messageList = [...t.messageList, ...list];
              }
              setTimeout(() => {
                t.to_footer();
              }, 500);
            }
            // }
          });
        });
      });

      // tim.on(TIM.EVENT.PROFILE_UPDATED, function (event) {
      //   console.log("收到自己或好友的资料变更通知", event);
      // });

      // tim.on(TIM.EVENT.BLACKLIST_UPDATED, function (event) {
      //   console.log("收到黑名单列表更新通知", event);
      // });

      // tim.on(TIM.EVENT.ERROR, function (event) {
      //   console.log("收到 SDK 发生错误通知，可以获取错误码和错误信息", event);
      // });

      // tim.on(TIM.EVENT.SDK_NOT_READY, function (event) {
      //   console.log(
      //     "收到 SDK 进入 not ready 状态通知，此时 SDK 无法正常工作",
      //     event
      //   );
      // });

      tim.on(TIM.EVENT.KICKED_OUT, function (event) {
        console.log("收到被踢下线通知", event);
      });

      // tim.on(TIM.EVENT.NET_STATE_CHANGE, function (event) {
      //   console.log("网络状态发生改变（v2.5.0 起支持）", event);
      // });

      // tim.on(TIM.EVENT.FRIEND_LIST_UPDATED, function (event) {
      //   console.log("收到好友列表更新通知（v2.13.0起支持）", event);
      // });

      // tim.on(TIM.EVENT.FRIEND_APPLICATION_LIST_UPDATED, function (event) {
      //   console.log("收到好友申请列表更新通知（v2.13.0起支持）", event);
      // });

      // tim.on(TIM.EVENT.FRIEND_GROUP_LIST_UPDATED, function (event) {
      //   console.log("收到好友分组列表更新通知（v2.13.0起支持）", event);
      // });

      await this.loginIM();
    },
    deleteGroupMember(row) {
      let t = this;
      this.$confirm("是否移除?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
      })
        .then(() => {
          API.deleteLiveMember(t.liveId, [row.userID]).then((res) => {
            if (res.data.code == 0) {
              t.$message.success("移除成功");
              t.sendMessage("-**-", "踢出直播间", row.name);
              t.getGroupMemberList();
            }
          });
        })
        .catch(() => {});
    },
    liveMemberPage(list) {
      let t = this;
      API.liveMemberPage({
        current: 1,
        size: list.length + 10,
        storeId: this.storeId,
        liveId: this.liveId,
      }).then((res) => {
        if (res.data.code == 0) {
          console.log(res.data.data);
          console.log(list, res.data.data.records);
          t.groupMaster = [];
          t.groupMemberList = [];
          list.forEach((item) => {
            res.data.data.records.forEach((row) => {
              if (item.role == "Owner" && item.userID == row.customId) {
                t.groupMaster.push({
                  ...item,
                  name: row.name,
                  coPresenterFlag: row.coPresenterFlag,
                });
              } else if (item.role != "Owner" && item.userID == row.customId) {
                t.groupMemberList.push({
                  ...item,
                  name: row.name,
                  coPresenterFlag: row.coPresenterFlag,
                });
              }
              if (row.customId == t.userCustomId) {
                t.userName = row.name;
              }
            });
          });
        }
      });
    },
    // 群成员列表
    async getGroupMemberList() {
      let t = this;
      let promiseMemberList = tim.getGroupMemberList({
        groupID: this.groupID,
        count: this.size,
        offset: 0,
      }); // 从 0 开始拉取 this.size 个 群成员
      promiseMemberList
        .then(function (imResponse) {
          // console.log("群成员列表", imResponse.data.memberList); // 群成员列表
          for (let groupMember of imResponse.data.memberList) {
            if (groupMember.muteUntil * 1000 > Date.now()) {
              if (groupMember.userID == t.userCustomId) {
                t.isMuteTime = true;
              }
            } else {
              if (groupMember.userID == t.userCustomId) {
                t.isMuteTime = false;
              }
              groupMember.muteUntil = 0;
            }
          }
          t.$nextTick(() => {
            let userID = [];
            imResponse.data.memberList.forEach((item) => {
              userID.push(item.userID);
            });

            t.KickOutLive = false;
            if (
              userID.indexOf(
                JSON.parse(localStorage.getItem("user_info")).content.customId
              ) == -1
            ) {
              t.KickOutLive = true;
            }
          });
          t.groupMaster = [];
          t.groupMemberList = [];
          imResponse.data.memberList.forEach((item) => {
            if (item.role == "Owner") {
              t.groupMaster.push(item);
            } else {
              t.groupMemberList.push(item);
            }
          });
          t.liveMemberPage(imResponse.data.memberList);
          // t.groupMemberList = imResponse.data.memberList;
        })
        .catch(function (imError) {
          console.warn("getGroupMemberList error:", imError);
        });
    },
    // 开始登录
    async loginIM() {
      let t = this;
      let promise = tim.login({
        userID: this.userCustomId,
        userSig: this.userSig,
      });
      promise
        .then(function (imResponse) {
          console.log("开始登录"); // 登录成功
          // t.sendMessage("-**-", "欢迎进入直播间", "");
          if (imResponse.data.repeatLogin === true) {
            // 标识帐号已登录，本次登录操作为重复登录。v2.5.1 起支持
            console.log("开始登录");
          }
        })
        .catch(function (imError) {
          console.warn("login error:", imError); // 登录失败的相关信息
        });
    },
    // 登出成功
    async logoutIM() {
      let promise = tim.logout();
      promise
        .then(function (imResponse) {
          console.log("登出成功"); // 登出成功
        })
        .catch(function (imError) {
          console.warn("logout error:", imError);
        });
    },
    async getUserSig() {
      let t = this;
      API.getUserSig({
        customId: this.userCustomId,
      }).then((res) => {
        if (res.data.code == 0) {
          t.userSig = res.data.data;
          // IM
          t.initIM();
        }
      });
    },
    async getBbUserSig() {
      let t = this;
      API.getUserSig({
        customId: this.liveId,
      }).then((res) => {
        if (res.data.code == 0) {
          t.userSigBb = res.data.data;
          // 互动白板
          t.initTeduBoard();
        }
      });
    },
    // 获取拉流地址
    getPlayStreamUrl() {
      let t = this;
      if (t.userCustomId != t.customId) {
        API.getPlayStreamUrl({
          liveId: this.liveId,
        }).then((res) => {
          if (res.data.code == 0) {
            let player = TCPlayer("player-container-id", {}); // player-container-id 为播放器容器 ID，必须与 html 中一致
            player.src(res.data.data); // url 播放地址
          }
        });
      }
    },
    // 开始直播
    startLive() {
      let t = this;
      API.startLive({
        liveId: t.liveId,
      }).then((res) => {
        if (res.data.code == 0) {
          let time = new Date().getTime();
          let startTime = null;
          if (t.startTime) {
            startTime = new Date(t.startTime).getTime();
          } else {
            startTime = new Date().getTime();
          }

          timer = setInterval(() => {
            time += 1000;
            t.liveTime = t.duration(time - startTime);
          }, 1000);
        }
      });
    },
    // 摄像头
    tagCamera() {
      let t = this;
      if (t.userCustomId == t.customId) {
        if (this.isPM && this.isCamera) {
          t.$message.warning("当前开启屏幕共享，关闭摄像头会导致无法正常直播");
          return;
        }
        if (this.isCamera) {
          // 关闭摄像头
          // livePusher.stopCamera();
          livePusher.pauseVideo();
          this.isCamera = false;
        } else {
          // 打开摄像头
          // livePusher.startCamera();
          livePusher.resumeVideo();
          this.isCamera = true;
        }
      }
    },
    // 麦克风
    tagMicrophone() {
      let t = this;
      if (t.userCustomId == t.customId) {
        if (this.isMicrophone) {
          // 关闭麦克风
          // livePusher.stopMicrophone();
          livePusher.pauseAudio();
          this.isMicrophone = false;
        } else {
          // 打开麦克风
          // livePusher.startMicrophone();
          livePusher.pauseAudio();
          livePusher.resumeAudio();
          this.isMicrophone = true;
        }
      }
    },
    // 屏幕共享
    tagScreenSharing() {
      let t = this;
      this.isPM = true;
      t.isMicrophone = true;
      // 采集多路流
      let cameraStreamId = null;
      let screenStreamId = null;
      livePusher
        .startScreenCapture()
        .then((streamId) => {
          livePusher.stopCamera();
          screenStreamId = streamId;
          t.screenStreamId.push(streamId);
          if (!t.isCamera) {
            t.tagCamera();
          }
          // 设置画面布局
          videoEffectManager.setLayout([
            {
              streamId: screenStreamId,
              x: 640,
              y: 360,
              width: 1280,
              height: 720,
              zOrder: 1,
            },
          ]);
          if (!livePusher.isPushing()) {
            t.startLive();
            livePusher.startPush(t.pushStreamUrl);
          }
        })
        .catch((error) => {
          t.isPM = false;
          // 停止采集
          console.log("屏幕分享失败：" + error.toString());
        });

      // // 设置镜像效果
      // videoEffectManager.setMirror({
      //   streamId: cameraStreamId,
      //   mirrorType: 1,
      // });
    },
    // 开始直播
    async init(pushStreamUrl) {
      let t = this;

      livePusher = new TXLivePusher();
      t.getUserSig();
      if (t.userCustomId != t.customId) {
        t.getPlayStreamUrl();
        return;
      }
      livePusher.setRenderView("localtracks");
      document
        .getElementById("localtracks")
        .getElementsByTagName("video")[0].muted = true;
      // 设置视频质量
      livePusher.setVideoQuality("1080p");
      // 设置音频质量
      livePusher.setAudioQuality("standard");
      // 自定义设置帧率
      livePusher.setProperty("setVideoFPS", 25);

      // 获取视频效果管理实例
      videoEffectManager = livePusher.getVideoEffectManager();
      // 开启本地混流
      videoEffectManager.enableMixing(true);
      // 对混流参数进行设置，主要是设置最终混流后生成视频的分辨率和帧率。
      videoEffectManager.setMixingConfig({
        videoWidth: 1280,
        videoHeight: 720,
        videoFramerate: 15,
      });

      Promise.all([livePusher.startCamera(), livePusher.startMicrophone()])
        .then(function () {
          t.startLive();
          livePusher.startPush(pushStreamUrl);
          t.isCamera = true;
          t.isMicrophone = true;
        })
        .catch(function (error) {
          t.$message.warning(
            "打开摄像头、麦克风失败,请检查摄像头、麦克风是否打开"
          );
        });
      livePusher.setObserver({
        onWarning: function (code, message, data) {
          var WARNING_CODE = {
            CAMERA_INTERRUPTED: -1005, // 摄像头中断事件
            MICROPHONE_INTERRUPTED: -1006, // 麦克风中断事件
            SCREEN_INTERRUPTED: -1007, // 屏幕分享中断事件
          };
          if (code === WARNING_CODE.SCREEN_INTERRUPTED) {
            t.isPM = false;
            livePusher.startCamera();
            // 停止采集
            livePusher.stopScreenCapture(data.streamId);
          }
        },
      });
    },
    // 关闭直播
    closeLive() {
      let t = this;
      if (t.userCustomId == t.customId) {
        //删除确认框
        t.$confirm("此操作将关闭直播, 是否继续?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            API.closeLive({
              liveId: this.liveId,
            }).then((res) => {
              if (res.data.code == 0) {
                t.$message.success("直播已结束");
                if (livePusher.isPushing()) {
                }
                livePusher.stopPush();
                livePusher.stopCamera();
                livePusher.stopMicrophone();
                clearInterval(timer);
                t.isEndLive = true;
              }
            });
          })
          .catch(() => {});
      } else {
        t.isExitLive = true;
      }
    },
    closeLiveTc() {
      let t = this;
      t.$emit("getMenuName", { name: "myLive" });
    },
    toLiveList() {
      this.$emit("getMenuName", {
        name: "liveList",
      });
    },
    // 时长
    duration(time) {
      let minuteTime = 0; // 分
      let hourTime = 0; // 小时
      let secondTime = parseInt(time / 1000);
      if (secondTime > 60) {
        minuteTime = parseInt(secondTime / 60);
        secondTime = parseInt(secondTime % 60);
        if (minuteTime > 60) {
          hourTime = parseInt(minuteTime / 60);
          minuteTime = parseInt(minuteTime % 60);
        }
      }
      secondTime =
        parseInt(secondTime) > 9
          ? parseInt(secondTime)
          : "0" + parseInt(secondTime);
      minuteTime =
        parseInt(minuteTime) > 9
          ? parseInt(minuteTime)
          : "0" + parseInt(minuteTime);
      hourTime =
        parseInt(hourTime) > 9 ? parseInt(hourTime) : "0" + parseInt(hourTime);

      return hourTime + ":" + minuteTime + ":" + secondTime;
    },
    // 查询直播设置
    getSetting() {
      let t = this;
      API.getSetting({
        liveId: this.liveId,
      }).then((res) => {});
    },
    // 获取用户直播间权限标识
    getLivePermission() {
      let t = this;
      API.getLivePermission({
        liveId: this.liveId,
        customId: this.userCustomId,
      }).then((res) => {
        console.log(res);
        t.livePermission = res.data.data;
      });
    },
    // 获取直播推流地址
    getPublishUrl() {
      let t = this;
      API.getPublishUrl({
        liveId: this.liveId,
      }).then((res) => {
        if (res.data.code == 0) {
          t.publishUrl = res.data.data;
        }
      });
    },
    // 获取直播拉流地址
    // getPlayUrl() {
    //   let t = this;
    //   API.getPlayUrl(
    //     {
    //       liveId: this.liveId,
    //     },
    //     "RTMP|HLS|HDL"
    //   ).then((res) => {});
    // },
    //getUserSig
    async getRoomToken() {
      let t = this;
      API.getRoomToken({
        liveId: this.liveId,
      }).then((res) => {
        t.roomToken = res.data.data;
        t.init();
      });
    },
  },
  mounted() {},
  beforeDestroy() {
    livePusher.stopPush();
    livePusher.stopCamera();
    livePusher.stopMicrophone();
    this.messageList = [];
    this.logoutIM();
    // this.stopWhiteboardPush();
    this.screenStreamId.forEach((item) => {
      livePusher.stopScreenCapture(item);
    });
  },
};
</script>
<style lang="scss" scoped>
.live {
  background: #f7f9fa !important;
}
.el-dropdown-link {
  cursor: pointer;
  color: #409eff;
  img {
    width: 20px;
    height: 20px;
  }
}
.el-icon-arrow-down {
  font-size: 12px;
}
.liveSetting {
  #remotetracks {
    width: 100px;
    height: 100px;
    position: absolute;
    top: 100px;
    left: 0;
    z-index: 10;
  }

  .warp {
    display: flex;
    // width: 1651px;
    // margin: 20px auto;
    // position: relative;
    // left: -148px;

    #localtracks_warp {
      width: 1115px;
      height: 648px;
      background: #e6eef4 !important;
      border-radius: 10px;
      overflow: hidden;
      position: relative;
      .name {
        position: absolute;
        left: 0;
        top: 0;
        width: 96px;
        height: 30px;
        background: #568abf;
        box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.25);
        border-radius: 6px 0px 6px 0px;
        z-index: 5;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #ffffff;
        line-height: 30px;
        text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
        display: flex;
        align-items: center;
        justify-content: center;
        img {
          width: 24px;
          height: 24px;
        }
      }
      #localtracks,
      #player-container-id {
        width: 1115px;
        height: 648px;
        background: #000;
        position: relative;
      }
      .toolbar {
        position: absolute;
        bottom: 0;
        left: 0;
        display: flex;
        align-items: center;
        img {
          width: 15px;
          height: 15px;
          margin-right: 10px;
          padding: 5px;
          cursor: pointer;
        }
      }
    }
    .cz_warp {
      width: 277px;
      height: 648px;
      background: #ffffff;
      border-radius: 16px;
      position: relative;
      overflow: hidden;
      box-sizing: border-box;

      & > div.live_options {
        padding: 16px 0;
        overflow: hidden;
        border-bottom: 1px solid rgba(229, 229, 229, 0.6);
        & > div {
          cursor: pointer;
          width: 69px;
          height: auto;
          background: #fff;
          border-radius: 16px;
          float: left;
          margin-left: 16px;
          box-sizing: border-box;
          position: relative;
          div:nth-of-type(1) {
            width: 69px;
            height: 52px;
            background: #568abf;
            border-radius: 8px;
            overflow: hidden;
            img {
              width: 36px;
              height: 36px;
              display: block;
              margin: 8px auto;
            }
          }

          div:nth-of-type(2) {
            margin-top: 8px;
            text-align: center;
            height: 24px;
            font-size: 16px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #568abf;
            line-height: 24px;
          }
          div.del {
            position: absolute;
            top: 50%;
            left: 0%;
            width: 100%;
            height: 100%;
            height: 2px;
            background: rgba(227, 28, 28, 0.75);
            transform: rotate(135deg);
          }
        }
        & > div.act {
          div:nth-of-type(1) {
            background: #f2f2f2;
          }
          div:nth-of-type(2) {
            color: #8c8c8c;
          }
        }
      }
      & > div.display {
        height: 35px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        border-bottom: 1px solid rgba(229, 229, 229, 0.6);
        img {
          width: 20px;
          height: 20px;
        }
      }
      & > div.list_warp {
        position: relative;
        top: 0;
        left: 0;
        z-index: 10;
        width: 277px;
        height: 648px;
        background: #ffffff;
        border-radius: 16px;
        padding: 0 16px;
        box-sizing: border-box;
        .list {
          display: flex;
          align-items: center;
          width: 277px;
          margin-left: -16px;
          position: relative;
          z-index: 2;
          background: #ffffff;
          div {
            flex: 1;
            cursor: pointer;
            font-size: 14px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: rgba(0, 0, 0, 0.2);
            line-height: 44px;
            text-align: center;
            width: 138px;
            height: 44px;
            position: relative;
            div {
              position: absolute;
              bottom: 0;
              width: 59px;
              height: 3px;
              background: #fff;
              border-radius: 2px;
              left: 50%;
              transform: translateX(-50%);
            }
          }
          div.active {
            color: rgba(50, 50, 51, 1);
            div {
              background: #568abf;
            }
          }
        }
        .user_list,
        .message_list {
          box-sizing: border-box;
          padding: 16px;
          padding-top: 0;
          position: absolute;
          top: 48px;
          left: 0;
          right: 0;
          bottom: 0;
          background: #e6eef4;
          .userList_warp {
            margin-bottom: 12px;
            overflow-y: auto;
            &::-webkit-scrollbar {
              width: 4px;
            }
            &::-webkit-scrollbar-track {
              -webkit-box-shadow: inset006pxrgba(0, 0, 0, 0.3);
              border-radius: 10px;
            }
            &::-webkit-scrollbar-thumb {
              border-radius: 10px;
              background: rgba(0, 0, 0, 0.1);
              -webkit-box-shadow: inset006pxrgba(0, 0, 0, 0.5);
            }
            &::-webkit-scrollbar-thumb:window-inactive {
              background: rgba(0, 0, 0, 0.1);
            }
          }
          .send_user_warp {
            display: flex;
            justify-content: space-between;
            padding: 8px 0px;
            margin-top: 10px;
            .el-button {
              flex: 1;
            }
          }
          .user_warp {
            display: flex;
            justify-content: space-between;
            padding: 8px 0px;
            border-bottom: 1px solid #f2f2f2;
            .user {
              display: flex;
              align-items: center;
              & > div:nth-of-type(1) {
                width: 24px;
                height: 24px;
                box-sizing: border-box;
                background: #568abf;
                color: #fff;
                line-height: 24px;
                text-align: center;
                border-radius: 50%;
                font-size: 16px;
                margin-right: 8px;
              }
              & > div.zc {
                height: 20px;
                background: #568abf;
                border-radius: 16px;
                font-size: 14px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #ffffff;
                line-height: 20px;
                text-align: center;
                padding: 0 10px;
                margin-right: 8px;
              }
              & > div:nth-of-type(2) {
                position: relative;
                display: flex;
                align-items: center;
                .lx {
                  height: 20px;
                  background: #ffbd5a;
                  border-radius: 16px;
                  font-size: 14px;
                  font-family: PingFangSC-Regular, PingFang SC;
                  font-weight: 400;
                  color: #ffffff;
                  line-height: 20px;
                  text-align: center;
                  padding: 0 10px;
                  margin-right: 8px;
                }
                .name {
                  width: 95px;
                  overflow: hidden;
                  white-space: nowrap;
                  text-overflow: ellipsis;
                }
              }
            }
            & > div:nth-of-type(2) {
              display: flex;
              align-items: center;
              .jy {
                position: relative;
                right: 0;
                img {
                  margin-left: 8px;
                  width: 20px;
                  height: 20px;
                  margin-right: 8px;
                }
                div.del {
                  position: absolute;
                  top: 50%;
                  left: 8px;
                  width: 20px;
                  height: 2px;
                  background: rgba(227, 28, 28, 0.75);
                  transform: rotate(135deg);
                }
              }
            }
          }
          .send_warp {
            display: flex;
            align-items: flex-end;
            width: 277px;
            height: 89px;
            padding: 8px;
            box-sizing: border-box;
            background: #ffffff;
            margin-left: -16px;
            border-radius: 0px 0px 10px 10px;
            .el-textarea__inner {
              &::-webkit-scrollbar {
                width: 4px;
              }
              &::-webkit-scrollbar-track {
                -webkit-box-shadow: inset006pxrgba(0, 0, 0, 0.3);
                border-radius: 10px;
              }
              &::-webkit-scrollbar-thumb {
                border-radius: 10px;
                background: rgba(0, 0, 0, 0.1);
                -webkit-box-shadow: inset006pxrgba(0, 0, 0, 0.5);
              }
              &::-webkit-scrollbar-thumb:window-inactive {
                background: rgba(0, 0, 0, 0.1);
              }
            }
            .el-button {
              padding: 0 8px;
            }
          }
          .message_warp {
            margin-bottom: 12px;
            overflow-y: scroll;
            &::-webkit-scrollbar {
              width: 4px;
            }
            &::-webkit-scrollbar-track {
              -webkit-box-shadow: inset006pxrgba(0, 0, 0, 0.3);
              border-radius: 10px;
            }
            &::-webkit-scrollbar-thumb {
              border-radius: 10px;
              background: rgba(0, 0, 0, 0.1);
              -webkit-box-shadow: inset006pxrgba(0, 0, 0, 0.5);
            }
            &::-webkit-scrollbar-thumb:window-inactive {
              background: rgba(0, 0, 0, 0.1);
            }
            & > .message_scroll {
              padding: 16px 0;
              & > div {
                font-size: 12px;
                margin-bottom: 8px;
                color: #000;
                .tz {
                  font-size: 12px;
                  color: #8c8c8c;
                  text-align: center;
                  width: 100%;
                  margin: 16px 0;
                }
                .tx {
                  width: 24px;
                  height: 24px;
                  box-sizing: border-box;
                  background: #568abf;
                  color: #fff;
                  line-height: 24px;
                  text-align: center;
                  border-radius: 50%;
                  font-size: 16px;
                  margin-top: 6px;
                }
              }
              .right_warp {
                display: flex;
                overflow: hidden;
                justify-content: flex-end;
              }
              .left_warp {
                text-align: left;
                display: flex;
                justify-content: flex-start;
              }
              .right {
                position: relative;
                display: table;
                max-width: 168px;
                padding: 8px;
                font-size: 12px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #ffffff;
                line-height: 20px;
                border-radius: 8px;
                background: #568abf;
                overflow-wrap: anywhere;
                margin-right: 8px;
                &::after {
                  position: absolute;
                  right: -8px;
                  top: 10px;
                  content: "";
                  border: 4px solid transparent;
                  border-left-color: #e6eef4;
                }
              }
              .left {
                position: relative;
                display: table;
                max-width: 168px;
                padding: 8px;
                border-radius: 8px;
                background-color: #fff;
                overflow-wrap: anywhere;
                margin-left: 8px;
                font-size: 12px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #595959;
                line-height: 20px;
                &::after {
                  position: absolute;
                  left: -8px;
                  top: 10px;
                  content: "";
                  border: 4px solid transparent;
                  border-right-color: #e6eef4;
                }
              }
            }
          }
        }
      }

      & > div.del {
        width: 277px;
        height: 58px;
        line-height: 58px;
        background: #568abf;
        border-radius: 10px 10px 0px 0px;
        font-size: 18px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #ffffff;
        text-align: center;
        margin: 0;
        cursor: pointer;
      }
      & > div.timer {
        width: 100%;
        height: 32px;
        position: absolute;
        bottom: 60px;
        left: 0;
        z-index: 3;
        font-size: 18px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #8c8c8c;
        line-height: 32px;
        background: none;
        margin: 0 16px;
      }
    }
  }
}
.exitLive {
  font-size: 24px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #568abf;
  line-height: 32px;
  padding: 0 70px;
}
.endLive_warp {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
  background: rgba(0, 0, 0, 0.25);
  .endLive_warp_tc {
    width: 315px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    .endLive {
      width: 315px;
      height: 198px;
      background: url(../../images/live_bg.png);
      background-repeat: no-repeat;
      background-size: cover;
      overflow: hidden;
      img {
        width: 62px;
        height: 62px;
        display: block;
        margin: 36px auto 20px;
      }
      .live_title {
        font-size: 24px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #000000;
        line-height: 28px;
        text-align: center;
        margin-bottom: 8px;
      }
      .live_time {
        font-size: 18px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #8c8c8c;
        line-height: 32px;
        text-align: center;
        margin-bottom: 12px;
      }
    }
    .close {
      width: 315px;
      height: 48px;
      background: #ffffff;
      border-radius: 0px 0px 16px 16px;
      border-top: 1px dashed #ebedf0;
      font-size: 16px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #568abf;
      line-height: 48px;
      text-align: center;
      cursor: pointer;
    }
  }
}
.tcp-skin .vjs-big-play-button .vjs-button-icon {
  width: 100% !important;
  height: 100% !important;
}
.video-js .vjs-big-play-button {
  top: 50%;
  left: 50%;
  margin-left: 0;
  margin-top: 0;
  transform: translate(-50%, -50%);
}
</style>