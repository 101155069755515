import request from '@/utils/request.js'
const API = {
    // 推荐课程
    courseList: data => request({
        url: `/mall/pc/courseList`,
        method: 'get',
        params: data
    }),
    // 获取商品详情
    product: id => request({
        url: `/mall/pc/product/${id}`,
        method: 'get',
    }),
    // 获取商品小程序码
    wxaCode: id => request({
        url: `/mall/pc/wxaCode/${id}`,
        method: 'get',
    }),

}
export default API