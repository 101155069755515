<template>
  <div class="liveList">
    <div class="live_title">
      <div>可加入</div>
      <el-button type="primary" v-if="userType == 30" @click="toAddLive"
        >新建直播</el-button
      >
    </div>
    <div class="liveList_search">
      <el-select v-model="time" placeholder="请选择">
        <el-option
          v-for="item in timeList"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>
      <el-input
        v-model="liveTitle"
        placeholder="请输入直播间名称"
        class="input-with-select"
      >
        <el-button
          slot="append"
          @click="search"
          icon="el-icon-search"
        ></el-button>
      </el-input>
    </div>
    <div class="liveList_data">
      <div v-for="(item, index) in tableData">
        <div>
          <img :src="item.pictureUrl" />
          <div>{{ item.liveTitle }}</div>
          <div>主持人：{{ item.name }}</div>
          <div>创建时间：{{ item.createTime }}</div>
          <div v-if="item.status == 0" class="status0">待直播</div>
          <div v-if="item.status == 1" class="status1">正在直播</div>
        </div>
        <div @click="enterLive(item)" :class="item.status == 2 ? 'end' : ''">
          {{ item.status == 2 ? "已结束" : "进入直播间" }}
        </div>
      </div>
    </div>
    <div class="block">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="current"
        :page-sizes="[6, 15, 30, 60]"
        :page-size="size"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
  </div>
</template>
<script>
import API from "../../api/live.js";
import storeId from "../../api/storeId.js";
export default {
  name: "liveList",
  data() {
    return {
      userType: "",
      current: 1,
      size: 15,
      storeId: "",
      liveTitle: "",
      tableData: [],
      total: 0,
      time: 0,
      timeList: [
        {
          label: "按时间顺序排序",
          value: 0,
        },
      ],
    };
  },
  created() {
    this.getMenuPermission();
    this.storeId = storeId.geturlparam();
    this.joinLivePage();
  },
  methods: {
    getMenuPermission() {
      let t = this;
      API.getMenuPermission({
        customId: JSON.parse(localStorage.getItem("user_info")).content
          .customId,
      }).then((res) => {
        if (res.data.code == 0) {
          t.$nextTick(() => {
            if (res.data.data.indexOf("live_create") > -1) {
              t.userType = 30;
            } else {
              t.userType = "";
            }
          });
        }
      });
    },
    search() {
      this.current = 1;
      this.joinLivePage();
    },
    enterLive(row) {
      if (row.status != 2) {
        this.$emit("getMenuName", {
          name: "liveSetting",
          liveId: row.id,
          customId: row.customId,
          groupID: row.groupId,
        });
      }
    },
    toAddLive() {
      this.$emit("getMenuName", { name: "addLive" });
    },
    handleSizeChange(val) {
      this.size = val;
      this.joinLivePage();
    },
    handleCurrentChange(val) {
      this.current = val;
      this.joinLivePage();
    },
    joinLivePage() {
      let t = this;
      API.joinLivePage({
        current: this.current,
        size: this.size,
        storeId: this.storeId,
        liveTitle: this.liveTitle,
        // mobile: "string",
        // name: "string",
        // liveType: 0,
        status: 1,
        // startDateTimes: ["2023-02-22T03:24:54.116Z"],
        // endDateTimes: ["2023-02-22T03:24:54.116Z"],
        // onlyMin: true,
      }).then((res) => {
        if (res.data.code == 0) {
          t.tableData = res.data.data.records;
          t.current = res.data.data.current;
          t.size = res.data.data.size;
          t.total = res.data.data.total;
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.liveList {
  .block {
    display: flex;
    justify-content: center;
  }
  .live_title {
    font-size: 20px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #568abf;
    line-height: 32px;
    padding: 8px 24px;
    border-bottom: 1px solid #e5e5e5;
    display: flex;
    justify-content: space-between;
  }
  .liveList_search {
    margin: 16px 24px;
    .el-input {
      width: 370px;
      margin-left: 24px;
    }
  }
  .liveList_data {
    margin: 0 24px;
    overflow: hidden;
    & > div {
      float: left;
      margin-right: 24px;
      margin-bottom: 24px;
      & > div:nth-of-type(1) {
        width: 349px;
        height: 310px;
        background: #ffffff;
        box-shadow: 4px 4px 12px 0px rgba(0, 0, 0, 0.2);
        border-radius: 10px 10px 0px 0px;
        padding: 14px;
        box-sizing: border-box;
        position: relative;
        img {
          width: 310px;
          height: 177px;
          border-radius: 10px;
          display: block;
          margin: 0 auto;
        }
        div:nth-of-type(1) {
          width: 310px;
          font-size: 21px;
          margin: 14px auto;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #0f1b41;
          line-height: 32px;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }
        div:nth-of-type(2),
        div:nth-of-type(3) {
          width: 310px;
          margin: 0 auto;
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #0f1b41;
          line-height: 24px;
        }
        .status0,
        .status1 {
          position: absolute;
          right: 0;
          top: 0;
          z-index: 2;
          padding: 0 19px;
          height: 26px;
          background: #ffbd5a;
          box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.25);
          border-radius: 6px 0px 6px 0px;
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #ffffff;
          line-height: 26px;
          text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
        }
        .status1 {
          background: #568abf;
        }
      }
      & > div:nth-of-type(2) {
        width: 349px;
        height: 58px;
        line-height: 58px;
        text-align: center;
        background: #e6eef4;
        border-radius: 0px 0px 10px 10px;
        font-size: 18px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #568abf;
        position: relative;
        cursor: pointer;
        z-index: 5;
      }
      & > div.end {
        background: #e5e5e5;
        color: #8c8c8c;
      }
    }
  }
}
</style>