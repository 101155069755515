<!--
 * @Description: word查看
 * @Date: 2023-01-02 15:17:43
 * @LastEditTime: 2023-01-05 09:48:16
 * @FilePath: \massive-web-site\src\components\wordViewer.vue
-->
<template>
  <div class="word_container">
    <el-button
      @click="fullScreen"
      class="el-icon-full-screen word_container_button"
    ></el-button>
    <div ref="word" id="wordViewer"></div>
  </div>
</template>

<script>
var docxx = require("docx-preview");
export default {
  name: "wordView",
  props: ["src"],
  components: {},
  data() {
    return {
      vHtml: "",
      docType: "office",
    };
  },
  watch: {
    src() {
      this.creatHTML();
    },
  },
  created() {
    this.creatHTML();
    // 监听事件
    window.addEventListener("resize", this.onresize);
  },
  beforeDestroy() {
    // 取消监听事件
    window.removeEventListener("resize", this.onresize);
  },
  methods: {
    // 全屏
    // 监听是否全屏状态
    onresize(event) {
      // 利用屏幕分辨率和window对象的内高度来判断兼容IE
      let winFlag = window.innerHeight === window.screen.height;
      // 利用window全屏标识来判断 -- IE无效
      let isFull = window.fullScreen || document.webkitIsFullScreen;

      if (isFull === undefined) {
        this.isFull = winFlag;
      } else {
        this.isFull = winFlag || isFull;
      }
      console.log(winFlag); // true全屏   false不是全屏
    },
    fullScreen() {
      //判断各种浏览器 -全屏
      function launchFullScreen(element) {
        if (element.requestFullscreen) {
          element.requestFullscreen();
        } else if (element.mozRequestFullScreen) {
          element.mozRequestFullScreen();
        } else if (element.webkitRequestFullScreen) {
          element.webkitRequestFullScreen();
        } else if (element.msRequestFullscreen) {
          element.msRequestFullscreen();
        } else {
          wtx.info("当前浏览器不支持部分全屏！");
        }
      }
      //判断各种浏览器 -退出全屏
      function exitFullscreen(element) {
        console.log("exitFullscreen", element);
        if (document.exitFullscreen) {
          document.exitFullscreen();
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else if (document.webkitExitFullscreen) {
          document.webkitExitFullscreen();
        } else if (document.msExitFullscreen) {
          document.msExitFullscreen();
        }
      }

      launchFullScreen(document.getElementById("wordViewer")); // 单独元素显示全屏
    },
    creatHTML() {
      var xhr = new XMLHttpRequest();
      xhr.open("get", this.src, true);
      xhr.responseType = "arraybuffer";
      xhr.onload = (e) => {
        if (xhr.status === 200) {
          docxx.renderAsync(xhr.response, this.$refs.word);
        }
      };
      xhr.send();
    },
  },
};
</script>

<style>
.word_container {
  width: 100%;
  height: 100%;
}
#wordViewer {
  width: 100%;
  height: 100%;
  overflow-x: scroll;
  overflow-y: scroll;
}
.word_container_button {
  position: fixed;
  z-index: 100;
  bottom: 20%;
  left: 35%;
}
</style>