<template>
  <div class="menuList">
    <div class="menu_warp">
      <div v-if="userType == 30">
        <div
          :class="menuName == 'addLive' ? 'title active' : 'title'"
          @click="setMenuName('addLive')"
        >
          <img src="../../images/addlive.png" />
          新建直播
        </div>
      </div>
      <div>
        <div class="title">
          <img src="../../images/liveList.png" /> 直播列表
        </div>
        <div>
          <div
            v-if="userType == 30"
            :class="menuName == 'myLive' ? 'title2 active' : 'title2'"
            @click="setMenuName('myLive')"
          >
            我的直播
          </div>
          <div
            :class="menuName == 'liveList' ? 'title2 active' : 'title2'"
            @click="setMenuName('liveList')"
          >
            可加入
          </div>
          <div
            :class="menuName == 'historicalRecord' ? 'title2 active' : 'title2'"
            @click="setMenuName('historicalRecord')"
          >
            历史记录
          </div>
        </div>
      </div>
      <div class="title disabled" v-if="userType == 20">在线作业</div>
      <div class="title disabled" v-if="userType == 20">课程日历</div>
    </div>
  </div>
</template>
  <script>
import API from "../../api/live.js";
export default {
  naem: "menuList",
  props: ["menuName"],
  data() {
    return {
      userType: "", //10-内部用户 20-家长 30-教师 40-其他
    };
  },
  created() {
    this.getMenuPermission();
  },
  methods: {
    getMenuPermission() {
      let t = this;
      API.getMenuPermission({
        customId: JSON.parse(localStorage.getItem("user_info")).content
          .customId,
      }).then((res) => {
        if (res.data.code == 0) {
          t.$nextTick(() => {
            if (res.data.data.indexOf("live_create") > -1) {
              t.userType = 30;
              t.$emit("getMenuName", { name: "addLive" });
            } else {
              t.userType = "";
              t.$emit("getMenuName", { name: "liveList" });
            }
          });
        }
      });
    },
    setMenuName(title) {
      this.$emit("getMenuName", { name: title });
    },
    handleOpen(key, keyPath) {},
    handleClose(key, keyPath) {},
  },
};
</script>
  <style lang="scss" scoped>
.menuList {
  width: 220px;
  height: 824px;
  background: #e6eef4;
  border-radius: 16px;
  padding: 14px 0;
  box-sizing: border-box;
  .menu_warp {
    .title {
      height: 40px;
      font-size: 16px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #568abf;
      line-height: 40px;
      margin: 0 20px;
      padding: 0 20px;
      cursor: pointer;
      display: flex;
      align-items: center;
      img {
        width: 16px;
        height: 16px;
        margin-right: 10px;
      }
    }
    .title2 {
      cursor: pointer;
      width: 172px;
      height: 40px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #568abf;
      line-height: 40px;
      margin: 0 auto;
      padding: 0 40px;
      box-sizing: border-box;
    }
    .disabled {
      opacity: 0.5;
    }
    .title2.active,
    .title.active {
      background: rgba(86, 138, 191, 0.2);
      border-radius: 6px;
    }
  }
}
</style>