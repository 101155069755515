<template>
  <div class="homePage">
    <recommendedProductDetails
      v-if="productId"
      :productId="productId"
      @back="getBack"
    ></recommendedProductDetails>
    <div v-if="!productId">
      <div class="homePage_warp">
        <el-carousel trigger="click" height="376px">
          <el-carousel-item v-for="item in bannerList" :key="item">
            <img :src="item" />
          </el-carousel-item>
        </el-carousel>
        <div class="warp" v-if="token">
          <div class="title">
            <div>我的课程</div>
            <div @click="toMyLesson">查看更多</div>
          </div>
          <div class="courseList_warp">
            <div
              v-for="(item, index) in coursePageList"
              :key="index"
              @click="toviewLesson(item)"
            >
              <img :src="item.pictureUrl" />
              <div class="word1">{{ item.courseName }}</div>
              <div>{{ item.status == 0 ? "可学习" : "已失效" }}</div>
            </div>
          </div>
          <img
            v-if="coursePageList.length == 0"
            class="noData"
            src="../../images/teacher/noData.png"
          />
          <div v-if="coursePageList.length == 0" class="noDataTilte">
            请前往“课内海量阅读”小程序购买课程
          </div>
        </div>
        <div class="warp">
          <div class="title">推荐课程</div>
          <div class="course_warp">
            <div
              v-for="(item, index) in courseDataList"
              :key="index"
              @click="product(item.id)"
            >
              <img :src="item.pictureUrl" />
              <div class="word1">{{ item.productTitle }}</div>
              <div>￥{{ item.specPrice }}</div>
            </div>
          </div>
          <img
            v-if="courseDataList.length == 0"
            class="noData"
            src="../../images/teacher/noData.png"
          />
          <div v-if="courseDataList.length == 0" class="noDataTilte">
            暂无推荐课程
          </div>
        </div>
        <div class="warp">
          <div class="title">名师风采</div>
          <div class="teacher_warp">
            <img src="../../images/teacher/11.jpg" />
            <img src="../../images/teacher/22.jpg" />
            <img src="../../images/teacher/33.jpg" />
            <img src="../../images/teacher/44.jpg" />
          </div>
        </div>
      </div>
      <div class="homePage_fitter">
        <div class="one">
          <div>网站介绍</div>
          <div>
            “课内海量阅读”团队致力于打造一款集授课、售课、直播、在线文档类课件于一体的综合型平台。该平台旨在提供丰富多样的课程内容，满足教师和学生在学习中的各种需求。
          </div>
        </div>
        <div class="one">
          <div>联系方式</div>
          <div>
            <div>电话：13632822717</div>
            <div>邮箱：1689732616@qq.com</div>
            <div>地址：江西省南昌市红谷滩区红谷北大道968号绿地外滩城</div>
          </div>
          <div></div>
        </div>
        <div class="one">
          <div></div>
          <div>
            <img src="../../images/teacher/code2.jpg" />
          </div>
          <div>海读公众号</div>
        </div>
        <div class="one">
          <div></div>
          <div>
            <img src="../../images/teacher/code1.jpg" />
          </div>
          <div>海读小程序</div>
        </div>
      </div>
      <div class="fixed">
        版权所有 © 2023~{{ year }}年 南昌火慧文化传媒有限公司
        <a href="https://beian.miit.gov.cn/">赣ICP备2023012645号-1</a>
      </div>
      <el-popover
        popper-class="navigation_popper"
        placement="left"
        width="200"
        trigger="hover"
      >
        <img
          style="width: 100%; height: 100%"
          src="../../images/teacher/serviceCode.jpg"
        />
        <div slot="reference" class="service">
          <img src="../../images/teacher/service.png" />
          <div>联系客服</div>
        </div>
      </el-popover>
    </div>
  </div>
</template>
<script>
import MALL from "../../api/mall.js";
import LESSON from "../../api/lesson.js";
import OPERATION from "../../api/operation.js";
import storeId from "../../api/storeId.js";
import { getStore } from "@/utils/store.js";
import recommendedProductDetails from "./recommendedProductDetails.vue";
export default {
  name: "homePage",
  components: { recommendedProductDetails },
  data() {
    return {
      storeId: "",
      userInfo: "",
      bannerList: [],
      coursePageList: [],
      courseDataList: [],
      productId: "",
      token: "",
    };
  },
  created() {
    this.storeId = storeId.geturlparam();
    this.userInfo = getStore({ name: "user_info" }) || false;
    this.token = getStore({ name: "access_token" });
    this.getBanner();
    this.courseList();
    this.getUserCoursePage();
  },
  methods: {
    getBack() {
      this.productId = "";
    },
    product(id) {
      this.productId = id;
    },
    toviewLesson(row) {
      this.$emit("toviewLesson", row);
    },
    toMyLesson() {
      this.$emit("toMyLesson", "");
    },
    // 获取店铺的首页banner
    getBanner() {
      let t = this;
      OPERATION.getBanner({
        storeId: this.storeId,
      }).then((res) => {
        if (res.data.code == 0) {
          t.bannerList = res.data.data || [];
        }
      });
    },
    // 推荐课程
    courseList() {
      let t = this;
      MALL.courseList({
        storeId: this.storeId,
      }).then((res) => {
        if (res.data.code == 0) {
          t.courseDataList = res.data.data;
        }
      });
    },
    // 用户的课程信息
    getUserCoursePage() {
      let t = this;
      let token = getStore({ name: "access_token" });
      if (!token) {
        return;
      }
      LESSON.getUserCoursePage({
        current: 1,
        size: 4,
        storeId: this.storeId,
        customId: this.userInfo.customId,
        status: 0,
      }).then((res) => {
        if (res.data.code == 0) {
          t.coursePageList = res.data.data.records || [];
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.fixed {
  position: relative;
  top: -50px;
  margin: 0 auto 0;
  text-align: center;
  a {
    text-decoration: none;
    color: #333;
  }
}
.homePage {
  .homePage_warp {
    padding-bottom: 42px;
    width: 1392px;
    background: #ffffff;
    border-radius: 16px;
    margin: 12px auto;
    .el-carousel--horizontal {
      width: 1140px;
      height: 376px;
      border-radius: 8px;
      margin: 4px auto;
      .el-carousel__container,
      img {
        width: 100%;
        height: 100%;
      }
    }
    .warp {
      width: 1140px;
      margin: 16px auto;
      .noData {
        width: 162px;
        height: 144px;
        display: block;
        margin: 99px auto 42px;
      }
      .noDataTilte {
        height: 24px;
        font-size: 12px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #568abf;
        line-height: 24px;
        text-align: center;
      }
      .title {
        width: 1140px;
        height: 32px;
        font-size: 21px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #5f5f5f;
        line-height: 32px;
        margin: 0;
        margin-bottom: 16px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        & > div:nth-of-type(2) {
          width: 78px;
          height: 28px;
          background: #ffffff;
          border-radius: 6px;
          border: 1px solid #d8d8d8;
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #568abf;
          line-height: 28px;
          text-align: center;
          cursor: pointer;
        }
      }
      .courseList_warp {
        overflow: hidden;

        & > div {
          width: 272px;
          height: 251px;
          background: #ffffff;
          box-shadow: 4px 4px 12px 0px rgba(0, 0, 0, 0.1);
          border-radius: 8px;
          border: 1px solid #f2f2f2;
          float: left;
          img {
            width: 240px;
            height: 138px;
            border-radius: 10px;
            margin: 15px auto;
            display: block;
          }
          div:nth-of-type(1) {
            width: 234px;
            height: 24px;
            font-size: 18px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: rgba(0, 0, 0, 0.85);
            line-height: 24px;
            margin: 16px auto;
          }
          div:nth-of-type(2) {
            width: 66px;
            height: 26px;
            background: #ffbd5a;
            border-radius: 13px;
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #ffffff;
            line-height: 26px;
            text-align: center;
            margin: 0 auto;
          }
        }
        & > div:nth-of-type(2),
        & > div:nth-of-type(3),
        & > div:nth-of-type(4) {
          margin-left: 14px;
        }
      }
      .course_warp {
        overflow: hidden;
        & > div {
          width: 272px;
          height: 352px;
          background: #ffffff;
          box-shadow: 4px 4px 12px 0px rgba(0, 0, 0, 0.1);
          border-radius: 8px;
          border: 1px solid #f2f2f2;
          float: left;
          img {
            width: 241px;
            height: 241px;
            border-radius: 8px;
            margin: 15px auto;
            display: block;
          }
          div:nth-of-type(1) {
            width: 234px;
            height: 24px;
            font-size: 18px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: rgba(0, 0, 0, 0.85);
            line-height: 24px;
            margin: 16px auto;
          }
          div:nth-of-type(2) {
            height: 24px;
            font-size: 20px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #f55050;
            line-height: 24px;
            text-align: center;
            margin: 0 auto;
          }
        }
        & > div:nth-of-type(2),
        & > div:nth-of-type(3),
        & > div:nth-of-type(4) {
          margin-left: 14px;
        }
      }
      .teacher_warp {
        overflow: hidden;
        img {
          width: 272px;
          height: 481px;
          float: left;
        }
        img:nth-of-type(2),
        img:nth-of-type(3),
        img:nth-of-type(4) {
          margin-left: 17px;
        }
      }
    }
  }
  .homePage_fitter {
    width: 1440px;
    height: 255px;
    background: #e1e7ec;
    margin: 24px auto 0;
    display: flex;
    padding: 28px 54px;
    box-sizing: border-box;
    align-items: self-start;
    & > div {
      display: inline-block;
      margin-left: 24px;
    }
    .one {
      width: 498px;
      & > div:nth-of-type(1) {
        height: 24px;
        font-size: 18px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #8c8c8c;
        line-height: 24px;
        margin-bottom: 8px;
      }
      & > div:nth-of-type(2) {
        width: 498px;
        height: 120px;
        background: rgba(255, 255, 255, 0.5);
        border-radius: 8px;
        font-size: 14px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #5f5f5f;
        line-height: 24px;
        padding: 24px 33px;
        box-sizing: border-box;
        margin-bottom: 12px;
        div {
          height: 24px;
          font-size: 14px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #5f5f5f;
          line-height: 24px;
        }
      }
      & > div:nth-of-type(3) {
        height: 24px;
        font-size: 14px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #5f5f5f;
        line-height: 24px;
        margin-left: 33px;
        a {
          font-size: 14px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #5f5f5f;
          // text-decoration: none;
        }
      }
    }
    .one:nth-of-type(2) > div:nth-of-type(2) {
      padding: 24px 30px;
    }
    .one:nth-of-type(3),
    .one:nth-of-type(4) {
      width: 120px;
      border-radius: 8px;
    }
    .one:nth-of-type(3) > div:nth-of-type(2),
    .one:nth-of-type(4) > div:nth-of-type(2) {
      padding: 0;
      width: 120px;
      img {
        width: 120px;
        height: 120px;
        border-radius: 8px;
      }
    }
    .one:nth-of-type(3) > div:nth-of-type(3),
    .one:nth-of-type(4) > div:nth-of-type(3) {
      text-align: center;
      margin-left: 0;
    }
  }
  .service {
    width: 56px;
    position: fixed;
    bottom: 100px;
    right: 40px;
    z-index: 10;
    cursor: pointer;
    img {
      width: 48px;
      height: 48px;
      display: block;
      margin: 0 auto;
    }
    div {
      width: 56px;
      height: 24px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #568abf;
      line-height: 24px;
    }
  }
  .navigation_popper {
    bottom: 36px !important;
  }
}
</style>