/*
 * @Description: 登录
 * @Date: 2022-12-28 10:17:45
 * @LastEditTime: 2022-12-28 16:19:13
 * @FilePath: \massive-web-site\src\api\login.js
 */

import request from '@/utils/request.js'


const API = {
    getCode: params => request({
        url: '/admin/app/' + params,
        method: 'get',
    }),
    loginByMobile: (mobile, basicAuth, code, grant_type, scope, storeId) => request({
        url: '/auth/oauth2/token',
        headers: {
            isToken: false,
            'Authorization': basicAuth
        },
        method: 'post',
        params: { mobile: mobile, code: code, grant_type, scope, storeId }
    }),
    // 获取用户信息
    getUserInfo: params => request({
        url: '/admin/custom/' + params,
        method: 'get',
    }),
}

export default API