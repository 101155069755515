import request from '@/utils/request.js'
const API = {
    // 获取店铺的首页banner
    getBanner: data => request({
        url: `/operation/pc/getBanner`,
        method: 'get',
        params: data
    }),

}
export default API