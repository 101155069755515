<!--
 * @Description: 网站首页
 * @Date: 2022-12-27 10:59:44
 * @LastEditTime: 2023-02-01 09:34:41
 * @FilePath: \massive-web-site\src\view\home\index.vue
-->
<template>
  <div class="container" style="height: 100%">
    <div class="front" v-show="bodyContent == 'front'" style="height: 100%">
      <!-- 导航栏 -->
      <div class="nav">
        <div class="bar">
          <div class="title">课内海量阅读</div>
          <div class="navigation" @click="toComponent('index')">首页</div>
          <div class="navigation" @click="toComponent('my_lesson')">
            我的课程
          </div>
          <el-popover
            popper-class="navigation_popper"
            placement="bottom"
            width="200"
            trigger="hover"
          >
            <el-image
              class="bgi"
              src="./static/img/wechat_App.jpg"
              fit="fill"
            ></el-image>
            <div slot="reference" class="navigation">海读小程序</div>
          </el-popover>
          <div class="navigation" @click="toComponent('hairead_live')">
            海读直播
          </div>
        </div>
        <div class="buttons">
          <button class="login" @click="toPage('login')" v-show="!userInfo">
            登录
          </button>
          <el-dropdown v-show="userInfo">
            <span class="el-dropdown-link user_info_name">
              <el-avatar
                class="user_info_name_avatar"
                :size="36"
                :src="userInfo.avatar"
              ></el-avatar>
              {{ userInfo.name
              }}<i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item @click.native="logout"
                >退出登录</el-dropdown-item
              >
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </div>
      <div class="main">
        <!-- 主页 -->
        <!-- <el-image
          class="bgi"
          src="./static/img/index_background.png"
          fit="fill"
          v-show="frontContent == 'index'"
        ></el-image> -->
        <homePage
          @toMyLesson="getMyLesson"
          @toviewLesson="getviewLesson"
          v-if="frontContent == 'index' && bodyContent == 'front'"
        ></homePage>
        <!-- 我的课程 -->
        <div class="my_lesson" v-show="frontContent == 'my_lesson'">
          <el-card class="box-card">
            <div slot="header" class="clearfix">
              <span class="card_title">我的课程</span>
              <button class="card_button" @click="open_dialog_excode">
                兑换课程
              </button>
            </div>
            <el-empty
              image="./static/img/Empty_state_money.png"
              description="暂无课程"
              class="card_empty"
              v-show="lessonTableData.length == 0"
            >
              <div class="card_empty_text_box">
                <div class="card_empty_text">
                  您可以前往小程序挑选心仪的课程
                </div>
              </div>
            </el-empty>
            <div class="my_lesson_table" v-show="lessonTableData.length > 0">
              <div class="my_lesson_table_box">
                <el-card
                  class="box-card"
                  shadow="hover"
                  v-for="(item, index) in lessonTableData"
                  :key="index"
                >
                  <el-image
                    class="my_lesson_table_box_cover"
                    :src="item.pictureUrl"
                    fit="fill"
                    @click="viewLesson(item)"
                  ></el-image>
                  <div
                    class="my_lesson_table_box_title"
                    @click="viewLesson(item)"
                  >
                    {{ item.courseName }}
                  </div>
                  <div
                    class="my_lesson_table_box_footer"
                    @click="viewLesson(item)"
                  >
                    <div class="my_lesson_table_box_footer_time">
                      {{ item.startTime
                      }}{{ item.endTime ? "~" + item.endTime : "" }}
                    </div>
                    <div
                      :class="
                        item.status == '0'
                          ? 'my_lesson_table_box_tag_enable'
                          : 'my_lesson_table_box_tag_disable'
                      "
                    >
                      {{ item.status == "0" ? "可学习" : "已失效" }}
                    </div>
                  </div>
                </el-card>
              </div>
              <!-- 分页 -->
              <div class="my_lesson_table_pagination">
                <el-pagination
                  @size-change="handleSizeChange"
                  @current-change="handleCurrentChange"
                  :current-page="pagination.current"
                  :page-sizes="[6, 15, 30, 60]"
                  :page-size="pagination.size"
                  layout="total, sizes, prev, pager, next, jumper"
                  :total="pagination.total"
                >
                </el-pagination>
              </div>
            </div>
          </el-card>
        </div>
        <!-- 课程信息 -->
        <div class="lesson_info" v-show="frontContent == 'lesson_info'">
          <!-- 课程信息-头部信息 -->
          <el-card class="box-card">
            <div slot="header" class="clearfix">
              <span class="card_title">课程信息</span>
            </div>
            <div class="lesson_info_box">
              <el-image
                class="lesson_info_box_cover"
                :src="currentLesson.pictureUrl"
                fit="fill"
              ></el-image>
              <div class="lesson_info_box_text">
                <div class="lesson_info_box_text_title">
                  {{ currentLesson.courseName }}
                </div>
                <div class="lesson_info_box_text_content">
                  <div class="lesson_info_box_text_time">
                    {{ currentLesson.startTime
                    }}{{
                      currentLesson.endTime ? "~" + currentLesson.endTime : ""
                    }}
                  </div>
                  <div
                    :class="
                      currentLesson.status == '0'
                        ? 'lesson_info_box_text_tag_enable'
                        : 'lesson_info_box_text_tag_disable'
                    "
                  >
                    {{ currentLesson.status == "0" ? "可学习" : "已失效" }}
                  </div>
                </div>
              </div>
            </div>
          </el-card>
          <!-- 课程信息-目录,简介,教师信息 -->
          <el-card class="box-card">
            <div class="lesson_info_body">
              <el-tabs v-model="activeName" @tab-click="handleClickTab">
                <el-tab-pane label="课程目录" name="first">
                  <div class="lesson_info_body_list">
                    <div
                      v-for="(item, index) in lessonList"
                      :key="index"
                      class="lesson_info_body_list_chapter"
                    >
                      <div
                        class="lesson_info_body_list_chapter_title"
                        @click="handelClickChapter(item)"
                        :style="item.isCurrent ? 'background: #eef3f8;' : ''"
                      >
                        <div class="lesson_info_body_list_chapter_title_left">
                          <div
                            class="lesson_info_body_list_chapter_title_left_text1"
                          >
                            {{ item.title }}
                          </div>
                          <div
                            class="lesson_info_body_list_chapter_title_left_text2"
                          >
                            <el-image
                              class="lesson_info_body_list_chapter_title_left_text2_img"
                              src="./static/img/video.png"
                              fit="fill"
                            ></el-image>
                            {{ item.number }}课程
                            <!-- {{
                              currentLesson.playType == 0
                                ? " |" + item.time
                                : ""
                            }} -->
                          </div>
                        </div>
                        <div class="lesson_info_body_list_chapter_title_right">
                          <el-image
                            v-show="!item.isCurrent"
                            class="lesson_info_body_list_chapter_title_right_img"
                            src="./static/img/dropDown.png"
                            fit="fill"
                          ></el-image>
                          <el-image
                            v-show="item.isCurrent"
                            class="lesson_info_body_list_chapter_title_right_img"
                            src="./static/img/dropUp.png"
                            fit="fill"
                          ></el-image>
                        </div>
                      </div>
                      <div
                        v-for="(singleLesson, j) in item.children"
                        :key="j"
                        class="lesson_info_body_list_chapter_single_lesson"
                        v-show="item.isCurrent"
                      >
                        <div
                          class="lesson_info_body_list_chapter_single_lesson_left"
                        >
                          <div
                            class="lesson_info_body_list_chapter_single_lesson_left_text1"
                          >
                            {{ singleLesson.title }}
                          </div>
                          <div
                            class="lesson_info_body_list_chapter_single_lesson_left_text2"
                          >
                            {{ singleLesson.type ? singleLesson.type : "" }}
                            <!-- {{
                              singleLesson.unlockTime
                                ? singleLesson.unlockTime
                                : singleLesson.fileType == 0 ||
                                  singleLesson.fileType == 1
                                ? singleLesson.time
                                : ""
                            }} -->
                          </div>
                        </div>
                        <div
                          class="lesson_info_body_list_chapter_single_lesson_right"
                        >
                          <!-- <button
                            class="lesson_info_body_list_chapter_single_lesson_right_button1"
                            v-show="
                              !singleLesson.isLocked &&
                              !!singleLesson.affixFileId
                            "
                            @click="donwload(singleLesson.affixFileId)"
                            disabled
                          >
                            下载附件
                          </button> -->
                          <button
                            class="lesson_info_body_list_chapter_single_lesson_right_button2"
                            v-show="
                              !singleLesson.isLocked && !!singleLesson.fileId
                            "
                            @click="handleClickPlayLesson(singleLesson, item)"
                          >
                            {{
                              singleLesson.fileType == 0
                                ? "播放音频"
                                : singleLesson.fileType == 1
                                ? "播放课程"
                                : singleLesson.fileType == 2
                                ? "查看文档"
                                : "查看PPT"
                            }}
                          </button>
                          <el-image
                            v-show="singleLesson.isLocked"
                            class="lesson_info_body_list_chapter_single_lesson_right_img"
                            src="./static/img/locked.png"
                            fit="fill"
                            @click="checkLock(singleLesson)"
                          ></el-image>
                        </div>
                      </div>
                    </div>
                  </div>
                </el-tab-pane>
                <el-tab-pane label="课程简介" name="second">
                  <div
                    class="lesson_info_body_details"
                    style="white-space: pre-wrap"
                  >
                    {{ currentLessonDetail.remark }}
                  </div>
                </el-tab-pane>
                <el-tab-pane label="教师信息" name="third" v-if="isTeacherList">
                  <div class="lesson_info_body_teachers">
                    <div
                      class="lesson_info_body_teachers_info"
                      v-for="(item, index) in teacherList"
                      :key="index"
                      :style="
                        index != teacherList.length - 1
                          ? 'border-bottom:1px solid #E5E5E5 ;'
                          : ''
                      "
                    >
                      <el-image
                        class="lesson_info_body_teachers_info_img"
                        :src="item.pictureUrl"
                        fit="fill"
                      ></el-image>
                      <div class="lesson_info_body_teachers_info_content">
                        <div class="lesson_info_body_teachers_info_content_top">
                          <div
                            class="lesson_info_body_teachers_info_content_name"
                          >
                            {{ item.name }}
                          </div>
                          <button
                            class="lesson_info_body_teachers_info_content_button"
                            @click="viewTeacherDetail(item)"
                          >
                            详情
                          </button>
                        </div>
                        <div
                          class="lesson_info_body_teachers_info_content_remark"
                        >
                          {{ item.simpleInfo }}
                        </div>
                      </div>
                    </div>
                  </div>
                </el-tab-pane>
              </el-tabs>
            </div>
          </el-card>
        </div>
        <!-- 音视频,文档查看 -->
        <div class="lesson_player" v-if="frontContent == 'lesson_player'">
          <div
            class="lesson_player_left"
            @contextmenu="handleMouse"
            @mousedown="handleMouse"
          >
            <VideoPlay
              v-show="currentSingleLesson.fileType == 1"
              :videoUrl="VideoPlayUrl"
              videoCover="封面链接"
              :autoplay="false"
              :controls="true"
              :loop="false"
              :muted="false"
              preload="auto"
              :playWidth="96"
              zoom="contain"
            ></VideoPlay>
            <div
              class="lesson_player_left_audio"
              v-show="currentSingleLesson.fileType == 0"
            >
              <el-image
                class="lesson_player_left_disk"
                :style="
                  isPlaying
                    ? 'animation-play-state:running'
                    : 'animation-play-state:paused'
                "
                src="./static/img/disk.png"
                fit="fill"
              ></el-image>
              <audio
                class="lesson_player_left_audio_player"
                controls
                controlslist="nodownload"
                :src="VideoPlayUrl"
                type="audio/mp3"
                @play="isPlaying = true"
                @pause="isPlaying = false"
              ></audio>
            </div>
            <div
              class="lesson_player_left_document"
              v-if="currentSingleLesson.fileType == 2"
            >
              <!-- <pdfViewer
                v-if="fileSuffix == 'pdf' || fileSuffix == 'PDF'"
                :src="VideoPlayUrl"
              ></pdfViewer>
              <wordViewer
                v-if="fileSuffix == 'docx' || fileSuffix == 'DOCX'"
                :src="VideoPlayUrl"
              ></wordViewer> -->
              <fullscreen :fullscreen.sync="fullscreenType">
                <iframe
                  :style="
                    fullscreenType
                      ? 'height: 100%; width: 100%'
                      : 'height: 586px; width: 100%'
                  "
                  :src="
                    'https://ow365.cn/?i=31220&n=5&' +
                    (VideoPlayUrl.indexOf('https://') > -1 ? 'ssl=1&' : '') +
                    '&furl=' +
                    VideoPlayUrl
                  "
                  frameborder="1"
                >
                </iframe>
              </fullscreen>
              <img
                src="../../images/icon/qp.png"
                class="fullqp"
                @click="fullscreenTypeChange"
              />
            </div>
            <div
              class="lesson_player_left_document"
              v-if="currentSingleLesson.fileType == 3"
            >
              <div class="warp">
                <!-- <div class="top">{{ fileName }}</div>
                <div class="botton"></div> -->
                <!-- <iframe
                  style="height: 586px; width: 100%"
                  :src="'https://ow365.cn/?i=31220&n=5&furl=' + pptPlayUrl"
                  frameborder="1"
                >
                </iframe> -->

                <iframe
                  v-if="pptPlayUrl.indexOf('massive-public.knhlyd.com') > -1"
                  style="height: 586px; width: 100%"
                  :src="
                    'https://ow365.cn/?i=31220&n=5&' +
                    (pptPlayUrl.indexOf('https://') > -1 ? 'ssl=1&' : '') +
                    'furl=' +
                    pptPlayUrl
                  "
                  frameborder="1"
                >
                </iframe>
                <iframe
                  v-else
                  style="height: 586px; width: 100%"
                  :src="
                    'https://ow365.cn/?i=31221' +
                    (pptPlayUrl.indexOf('https://') > -1 ? 'ssl=1&' : '') +
                    '&n=5&furl=' +
                    pptPlayUrl
                  "
                  frameborder="1"
                >
                </iframe>
              </div>
            </div>
          </div>
          <div class="lesson_player_rigth">
            <div class="lesson_player_rigth_list">
              <div
                v-for="(item, index) in lessonList"
                :key="index"
                class="lesson_info_body_list_chapter"
              >
                <div
                  class="lesson_info_body_list_chapter_title"
                  @click="handelClickChapter(item)"
                  :style="item.isCurrent ? 'background: #eef3f8;' : ''"
                >
                  <div class="lesson_info_body_list_chapter_title_left">
                    <div class="lesson_info_body_list_chapter_title_left_text1">
                      {{ item.title }}
                    </div>
                    <div class="lesson_info_body_list_chapter_title_left_text2">
                      <el-image
                        class="lesson_info_body_list_chapter_title_left_text2_img"
                        src="./static/img/video.png"
                        fit="fill"
                      ></el-image>
                      {{ item.number }}课程
                      <!-- {{
                        currentLesson.playType == 0 ? " |" + item.time : ""
                      }} -->
                    </div>
                  </div>
                  <div class="lesson_info_body_list_chapter_title_right">
                    <el-image
                      v-show="!item.isCurrent"
                      class="lesson_info_body_list_chapter_title_right_img"
                      src="./static/img/dropDown.png"
                      fit="fill"
                    ></el-image>
                    <el-image
                      v-show="item.isCurrent"
                      class="lesson_info_body_list_chapter_title_right_img"
                      src="./static/img/dropUp.png"
                      fit="fill"
                    ></el-image>
                  </div>
                </div>
                <div
                  v-for="(singleLesson, j) in item.children"
                  :key="j"
                  :class="
                    singleLesson.isCurrent
                      ? 'lesson_player_rigth_body_list_chapter_single_lesson lesson_player_rigth_active'
                      : 'lesson_player_rigth_body_list_chapter_single_lesson'
                  "
                  style="cursor: pointer; margin: 10px 0"
                  v-show="item.isCurrent"
                  @click="handleClickPlayLesson(singleLesson, item)"
                >
                  <div class="lesson_info_body_list_chapter_single_lesson_left">
                    <el-image
                      v-show="
                        !singleLesson.isLocked &&
                        (singleLesson.fileType == 0 ||
                          singleLesson.fileType == 1) &&
                        singleLesson.isCurrent
                      "
                      class="lesson_info_body_list_chapter_single_lesson_left_img"
                      src="./static/img/videoactive_on.png"
                      fit="fill"
                    ></el-image>
                    <div
                      :class="
                        !singleLesson.isCurrent
                          ? 'lesson_info_body_list_chapter_single_lesson_left_text1'
                          : 'lesson_player_rigth_body_list_chapter_single_lesson_right_text1'
                      "
                    >
                      {{ singleLesson.title }}
                    </div>
                    <div
                      :class="
                        !singleLesson.isCurrent
                          ? 'lesson_info_body_list_chapter_single_lesson_left_text2'
                          : 'lesson_player_rigth_body_list_chapter_single_lesson_right_text2'
                      "
                    >
                      {{ singleLesson.type ? singleLesson.type : "" }}
                      <!-- {{
                        singleLesson.unlockTime
                          ? singleLesson.unlockTime
                          : singleLesson.fileType == 0 ||
                            singleLesson.fileType == 1
                          ? singleLesson.time
                          : ""
                      }} -->
                    </div>
                  </div>
                  <div
                    class="lesson_player_rigth_body_list_chapter_single_lesson_right"
                  >
                    <el-image
                      v-show="
                        !singleLesson.isLocked &&
                        (singleLesson.fileType == 0 ||
                          singleLesson.fileType == 1) &&
                        !singleLesson.isCurrent
                      "
                      class="lesson_info_body_list_chapter_title_right_img"
                      src="./static/img/videoactive.png"
                      fit="fill"
                    ></el-image>
                    <i
                      v-show="
                        !singleLesson.isLocked && singleLesson.fileType == 2
                      "
                      class="el-icon-view lesson_info_body_list_chapter_title_right_img"
                    ></i>
                    <!-- <i
                      v-show="
                        !singleLesson.isLocked && singleLesson.fileType == 3
                      "
                      class="el-icon-download lesson_info_body_list_chapter_title_right_img"
                      >打开文档</i
                    > -->
                    <el-image
                      v-show="singleLesson.isLocked"
                      class="lesson_info_body_list_chapter_single_lesson_right_img"
                      src="./static/img/locked.png"
                      fit="fill"
                      style="margin-right: 0px"
                    ></el-image>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- 教师简介详情 -->
        <div class="teacher_detail" v-show="frontContent == 'teacher_detail'">
          <el-card class="box-card">
            <div slot="header" class="clearfix">
              <span class="card_title">教师详情</span>
              <button
                class="card_button"
                style="
                  background: #ffffff;
                  color: #8c8c8c;
                  border: 1px solid #d8d8d8;
                  line-height: 24px;
                "
                @click="toComponent('lesson_info')"
              >
                返回
              </button>
            </div>
            <div
              class="teacher_detail_box"
              style="border-bottom: 1px solid #e5e5e5a3"
            >
              <div class="teacher_detail_box_left">
                <el-image
                  class="teacher_detail_box_left_img"
                  :src="teacherDetail.pictureUrl"
                  fit="fill"
                ></el-image>
              </div>
              <div class="teacher_detail_box_right">
                <div class="teacher_detail_box_right_title">
                  {{ teacherDetail.name }}
                </div>
                <div class="teacher_detail_box_right_info">
                  {{ teacherDetail.simpleInfo }}
                </div>
              </div>
            </div>
            <div class="teacher_detail_box">
              <div class="teacher_detail_box_left"></div>
              <div class="teacher_detail_box_right">
                <div class="teacher_detail_box_right_title">教师详情</div>
                <div
                  v-html="teacherDetail.detailInfo"
                  style="white-space: pre-wrap"
                ></div>
              </div>
            </div>
          </el-card>
        </div>
        <div v-if="frontContent == 'hairead_live'">
          <haireadLive></haireadLive>
        </div>
      </div>
    </div>
    <!-- 登录页 -->
    <div class="loginPage" v-show="bodyContent == 'login'">
      <div class="login_form">
        <div class="login_form_title">
          登录海量阅读
          <div class="login_form_title_line"></div>
        </div>
        <el-form ref="loginForm" :model="login" :rules="loginRule">
          <el-form-item prop="account">
            <el-input
              class="login_form_account"
              v-model="login.account"
              placeholder="手机号"
              prefix-icon="el-icon-user"
            ></el-input>
          </el-form-item>
          <el-form-item prop="code">
            <div class="login_form_code_box">
              <el-input
                class="login_form_code"
                v-model="login.code"
                placeholder="验证码"
                prefix-icon="el-icon-lock"
              ></el-input>
              <div
                class="login_form_code_button"
                @click="sendCode('loginForm')"
                :style="
                  login_form_code_button_flag
                    ? 'cursor: pointer'
                    : 'cursor: no-drop '
                "
              >
                {{ login_form_code_button_text }}
              </div>
            </div>
          </el-form-item>
        </el-form>
        <div class="login_form_submit" @click="loginByMobile('loginForm')">
          登录/注册
        </div>
      </div>
      <el-image
        class="bgi"
        src="./static/img/login_background.png"
        fit="fill"
      ></el-image>
    </div>
    <!-- 弹窗-兑换课程 -->
    <el-dialog
      :visible.sync="dialogVisible.exchangeCode"
      :show-close="false"
      :center="true"
      :before-close="exchangeCodeBeforeClose"
      class="dialog_excode"
    >
      <div slot="title" class="dialog_excode_title">兑换课程</div>
      <div class="dialog_excode_input">
        <el-input
          class="dialog_excode_input_excode"
          v-model="exchangeCodeOBJ.exchangeCode"
          placeholder="请输入兑换码"
        ></el-input>
        <div class="dialog_excode_input_vicode_box">
          <el-input
            class="dialog_excode_input_vicode"
            v-model="exchangeCodeOBJ.checkCode"
            placeholder="请输入验证码"
          ></el-input>
          <el-image
            class="dialog_excode_input_vicode_button"
            :src="codeUrl"
            fit="fill"
            @click="getCodeUrl"
          ></el-image>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <button
          @click="cancel_dialog_excode"
          class="dialog_excode_button_close"
        >
          取 消
        </button>
        <button
          class="dialog_excode_button_confirm"
          @click="confirm_dialog_excode"
        >
          确认兑换
        </button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import fullscreen from "vue-fullscreen";
import Vue from "vue";
Vue.use(fullscreen);
import { randomLenNum } from "@/utils/util.js";
import {
  setStore,
  getStore,
  removeStore,
  getAllStore,
  clearStore,
} from "@/utils/store.js";
import API_login from "@/api/login.js";
import API_lesson from "@/api/lesson.js";
import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
dayjs.extend(duration);
import VideoPlay from "@/components/videoPlayer.vue";
import pdfViewer from "@/components/pdfViewer.vue";
import wordViewer from "@/components/wordViewer.vue";
import haireadLive from "../live/index.vue";
import homePage from "./homePage.vue";
import storeId from "../../api/storeId.js";
export default {
  name: "home",
  components: {
    VideoPlay,
    pdfViewer,
    wordViewer,
    haireadLive,
    homePage,
  },
  data() {
    return {
      fullscreenType: false,
      pptPlayUrl: "",
      ishaireadLive: false,
      storeId: "",
      userInfo: {},
      bodyContent: "front", // front login
      frontContent: "index", // index my_lesson lesson_info lesson_player teacher_detail
      login: {
        account: "",
        code: "",
      },
      loginRule: {
        account: [
          { required: true, message: "手机号不能为空", trigger: "blur" },
          { min: 11, max: 11, message: "长度为 11 个字符", trigger: "blur" },
          {
            pattern: /^1[3|4|5|6|7|8|9]\d{9}$/,
            message: "手机号码格式不对，请重新输入",
            trigger: "blur",
          },
        ],
        code: [
          { required: true, message: "验证码不能为空", trigger: "blur" },
          { min: 6, max: 6, message: "长度为 6 个字符", trigger: "blur" },
        ],
      },
      login_form_code_button_text: "获取验证码",
      login_form_code_button_flag: true,
      dialogVisible: {
        exchangeCode: false,
      },
      lessonTableData: [], // 我的课程
      currentLesson: {}, // 当前课程
      currentLessonDetail: {}, // 当前课程
      pagination: {
        //分页
        size: 15, //一页显示的数据条数
        current: 1, //当前页
        total: 0, //数据总条数
      },
      activeName: "first", // 课程信息-标签页
      lessonList: [], // 课程目录
      currentChapter: {}, // 当前目录章节
      teacherList: [], // 教师列表
      teacherDetail: {}, // 教师详情
      codeUrl: "", // 兑换课程验证码图片地址
      exchangeCodeOBJ: {
        exchangeCode: "",
        randomStr: "",
        checkCode: "",
      },
      VideoPlayUrl: "", // 音视频,文档下载地址
      fileName: "", // 音视频,文档文件名
      fileSuffix: "", // 音视频,文档文件后缀
      isPlaying: false, // 音视频是否播放
      currentSingleLesson: {}, // 当前课程
    };
  },
  created() {
    this.storeId = storeId.geturlparam();
    this.userInfo = getStore({ name: "user_info" }) || false;
  },
  computed: {
    isTeacherList() {
      if (this.teacherList) {
        if (this.teacherList.length == 0) {
          return false;
        }
        return true;
      }
      return false;
    },
  },
  methods: {
    getMyLesson(e) {
      this.toComponent("my_lesson");
    },
    getviewLesson(e) {
      this.viewLesson(e);
    },
    // #region 主页/导航
    toPage(tag) {
      this.bodyContent = tag;
      this.storeId = storeId.geturlparam();
    },
    toComponent(tag) {
      this.frontContent = tag;
      this.storeId = storeId.geturlparam();
      if (
        (this.userInfo == {} || !this.userInfo || this.userInfo == "") &&
        tag != "index"
      ) {
        this.$message.info("请先登录");
        this.bodyContent = "login";
        this.frontContent = "index";
        return;
      }
      if (tag == "my_lesson") {
        this.page();
      }
      if (tag == "hairead_live") {
        this.ishaireadLive = true;
      }
    },
    fullscreenTypeChange() {
      this.fullscreenType = !this.fullscreenType;
    },
    geturlparam() {
      // window.location.href 获取地址
      // let url = window.location.href;
      // let storeId = url.split("study")[1];
      // if (storeId != undefined) {
      //   this.storeId = storeId;
      // } else {
      //   this.$message.info("网址错误");
      //   this.bodyContent = "front";
      //   this.frontContent = "index";
      // }
      let url = window.location.href;
      let arr = url.split("www");
      if (arr.length > 1) {
        this.storeId = "1584382180499255298"; // 线上
      } else {
        this.storeId = "1584382180499255298"; // 测试
      }
    },
    // #endregion 主页/导航

    // #region 我的课程
    viewLesson(currentLesson) {
      console.log(currentLesson);
      if (currentLesson.status == 1) {
        this.$message.info("课程已失效");
        return;
      }
      this.currentLesson = currentLesson;
      this.toComponent("lesson_info");
      this.getLessonDetail();
      this.getLessonList();
      this.getTeacherList();
    },
    page() {
      // 获取用户的课程列表
      const { current, size } = this.pagination;
      const query = {
        current,
        size,
        storeId: this.storeId,
        customId: this.userInfo.customId,
      };
      API_lesson.getUserCoursePage(query)
        .then((result) => {
          const { total, records } = result.data.data;
          this.pagination.total = total;
          this.lessonTableData = records;
        })
        .catch((err) => {});
    },
    initPage() {
      this.pagination = {
        //分页
        size: 15, //一页显示的数据条数
        current: 1, //当前页
        total: 0, //数据总条数
      };
    },
    handleSizeChange(val) {
      //处理分页size改变
      this.pagination.size = val;
      this.page();
    },
    handleCurrentChange(val) {
      //处理当前页页current改变
      this.pagination.current = val;
      this.page();
    },
    // #endregion 我的课程

    // #region 课程信息
    handleMouse(e) {
      e.preventDefault();
    },
    getLessonList() {
      API_lesson.treeTable({ courseId: this.currentLesson.courseId })
        .then((result) => {
          let lessonList = [];
          if (!!result.data.data) {
            result.data.data.map((item, index) => {
              lessonList.push({
                id: item.id,
                title: item.name,
                time: item.fileTime
                  ? item.fileTime > 3599
                    ? dayjs
                        .duration(item.fileTime, "seconds")
                        .format(" H小时 m分钟 s秒")
                    : item.fileTime > 59
                    ? dayjs
                        .duration(item.fileTime, "seconds")
                        .format(" m分钟 s秒")
                    : dayjs.duration(item.fileTime, "seconds").format(" s秒")
                  : "",
                number: item.childNum,
                isCurrent: false,
                children: [],
              });
              if (!!item.children) {
                item.children.map((child) => {
                  let isLocked = false;
                  if (this.currentLesson.playType == 1) {
                    if (child.unlockTime) {
                      isLocked = !dayjs().isAfter(dayjs(child.unlockTime));
                    }
                  }
                  lessonList[index].children.push({
                    id: child.id,
                    title: child.name,
                    time: child.fileTime
                      ? child.fileTime > 3599
                        ? dayjs
                            .duration(child.fileTime, "seconds")
                            .format(" H小时 m分钟 s秒")
                        : child.fileTime > 59
                        ? dayjs
                            .duration(child.fileTime, "seconds")
                            .format(" m分钟 s秒")
                        : dayjs
                            .duration(child.fileTime, "seconds")
                            .format(" s秒")
                      : "",
                    type: child.fileTypeName,
                    fileType: child.fileType,
                    affixFileId: child.affixFileId,
                    fileId: child.fileId,
                    isCurrent: false,
                    isLocked: isLocked,
                    unlockTimeOrigin: child.unlockTime,
                    unlockTime: child.unlockTime
                      ? dayjs(child.unlockTime).format(" M月D日 HH:mm")
                      : null,
                  });
                });
              }
            });
          }
          this.lessonList = lessonList;
        })
        .catch((err) => {});
    },
    getTeacherList() {
      API_lesson.courseTeacher({ courseId: this.currentLesson.courseId })
        .then((result) => {
          this.teacherList = result.data.data;
        })
        .catch((err) => {});
    },
    getLessonDetail() {
      API_lesson.getUserCourseDetail({ courseId: this.currentLesson.courseId })
        .then((result) => {
          this.currentLessonDetail = result.data.data;
        })
        .catch((err) => {});
    },
    handleClickTab(tab, event) {
      // console.log(tab, event);
    },
    handelClickChapter(item) {
      if (item.isCurrent) {
        item.isCurrent = false;
      } else {
        this.lessonList.map((chapter, index) => {
          if (chapter.id == item.id) {
            this.$set(this.lessonList[index], "isCurrent", true);
          } else {
            this.$set(this.lessonList[index], "isCurrent", false);
          }
        });
      }
    },
    handleClickPlayLesson(singleLesson, item) {
      if (singleLesson.isLocked) {
        this.checkLock(singleLesson);
      } else {
        this.isPlaying = false;
        if (singleLesson.fileType == 3) {
          this.currentSingleLesson = singleLesson;
          singleLesson.isCurrent = true;
          item.children.map((eachSingleLesson) => {
            if (eachSingleLesson.id != singleLesson.id) {
              eachSingleLesson.isCurrent = false;
            }
          });
          this.lessonList;
          API_lesson.download({ fileId: singleLesson.fileId })
            .then((result) => {
              this.pptPlayUrl = result.data.data.url;
              this.fileName = result.data.data.fileName;
              let tempArr = result.data.data.fileName.split(".");
              this.fileSuffix = tempArr[tempArr.length - 1] || null;
              // console.log(this.fileSuffix);
              this.toComponent("lesson_player");
            })
            .catch((err) => {});
        } else {
          this.currentSingleLesson = singleLesson;
          singleLesson.isCurrent = true;
          item.children.map((eachSingleLesson) => {
            if (eachSingleLesson.id != singleLesson.id) {
              eachSingleLesson.isCurrent = false;
            }
          });
          this.lessonList;
          API_lesson.download({ fileId: singleLesson.fileId })
            .then((result) => {
              this.VideoPlayUrl = result.data.data.url;
              this.fileName = result.data.data.fileName;
              let tempArr = result.data.data.fileName.split(".");
              this.fileSuffix = tempArr[tempArr.length - 1] || null;
              // console.log(this.fileSuffix);
              this.toComponent("lesson_player");
            })
            .catch((err) => {});
        }
      }
    },
    donwload(fileId) {
      this.$confirm("确认下载？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          API_lesson.download({ fileId })
            .then((result) => {
              window.location.href = result.data.data.url;
            })
            .catch((err) => {});
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
    },
    checkLock(singleLesson) {
      // console.log(singleLesson);
      let isLocked = !dayjs().isAfter(dayjs(singleLesson.unlockTimeOrigin));
      if (isLocked) {
        this.$message.info("还没到上课时间");
      } else {
        this.$message.success("解锁成功");
      }
      singleLesson.isLocked = isLocked;
    },
    viewTeacherDetail(row) {
      // 查看教师详情
      API_lesson.teacherInfo({ id: row.id })
        .then((result) => {
          this.teacherDetail = result.data.data;
          this.toComponent("teacher_detail");
        })
        .catch((err) => {});
    },
    // #endregion 课程信息

    // #region 弹窗-兑换码
    exchangeCodeBeforeClose() {},
    open_dialog_excode() {
      this.dialogVisible.exchangeCode = true;
      this.getCodeUrl();
    },
    getCodeUrl() {
      this.exchangeCodeOBJ.randomStr = randomLenNum(10);
      this.codeUrl = `/api/code?randomStr=${this.exchangeCodeOBJ.randomStr}`;
    },
    cancel_dialog_excode() {
      this.dialogVisible.exchangeCode = false;
      Object.keys(this.exchangeCodeOBJ).forEach((key) => {
        this.$set(this.exchangeCodeOBJ, key, "");
      });
    },
    confirm_dialog_excode() {
      API_lesson.exchangeCourse(this.exchangeCodeOBJ)
        .then((result) => {
          // console.log(result);
          if (result.data.success) {
            this.$message.success("兑换成功");
            this.page();
            this.dialogVisible.exchangeCode = false;
            Object.keys(this.exchangeCodeOBJ).forEach((key) => {
              this.$set(this.exchangeCodeOBJ, key, "");
            });
          } else {
            this.exchangeCodeOBJ.checkCode = "";
            this.getCodeUrl();
            this.$message.info(result.data.msg);
          }
        })
        .catch((err) => {
          this.exchangeCodeOBJ.checkCode = "";
          this.getCodeUrl();
        });
    },
    // #endregion

    // #region 登录/注册
    sendCode(formName) {
      // 发送验证码
      this.$refs[formName].validateField("account", (err) => {
        if (!err) {
          if (this.login_form_code_button_flag) {
            API_login.getCode(this.login.account)
              .then((result) => {
                // console.log(result);
                if (result.data.data == false) {
                  this.$message.info(result.data.msg);
                } else {
                  this.$message.success("发送成功");
                }
              })
              .catch((err) => {
                // console.log(err);
                this.$message.info(err);
              });
            this.login_form_code_button_flag = false;
            let count = 60;
            this.login_form_code_button_text = count + "s";
            const timer = setInterval(() => {
              count--;
              if (count == 0) {
                this.login_form_code_button_text = "获取验证码";
                this.login_form_code_button_flag = true;
                clearInterval(timer);
              } else {
                this.login_form_code_button_text = count + "s";
              }
            }, 1000);
          }
        }
      });
    },
    loginByMobile(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          API_login.loginByMobile(
            this.login.account,
            "Basic cGM6cGM=",
            this.login.code,
            "pc",
            "server",
            this.storeId
          )
            .then((result) => {
              // console.log(result);
              setStore({
                name: "access_token",
                content: result.data.access_token,
              });
              if (result.data.user_info.newUser) {
                this.$message.success("注册成功");
              } else {
                this.$message.success("登录成功");
              }
              API_login.getUserInfo(result.data.user_info.id)
                .then((result) => {
                  // console.log(result);
                  this.bodyContent = "front";
                  this.userInfo = result.data.data;
                  setStore({
                    name: "user_info",
                    content: result.data.data,
                  });
                })
                .catch((err) => {
                  // console.log(err);
                });
            })
            .catch((err) => {
              this.$message.info(err.data.msg);
            });
        }
      });
    },
    logout() {
      setStore({
        name: "access_token",
        content: "",
      });
      setStore({
        name: "user_info",
        content: "",
      });
      location.reload();
    },
    // #endregion
  },
};
</script>

<style scoped>
/* #region 首页 */
.front,
.loginPage {
  width: 100%;
}
.front {
  background: #f7f9fa;
}
.nav {
  width: 100%;
  min-width: 1392px;
  height: 56px;
  background: #2e2e30;
  display: flex;
  justify-content: space-between;
}
.bar {
  display: flex;
  justify-content: flex-start;
}
.buttons {
  margin: 0 39px;
}
.login {
  width: 50px;
  height: 28px;
  border-radius: 6px;
  border: 1px solid #ffffff;
  background: #ffffff00;
  color: white;
  cursor: pointer;
  margin: 14px 0;
}
.title {
  width: 120px;
  height: 56px;
  font-size: 20px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #ffffff;
  line-height: 56px;
  margin: 0 32px;
}
.navigation {
  height: 56px;
  height: 24px;
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #ffffff;
  line-height: 56px;
  cursor: pointer;
  margin: 0 48px;
}
.navigation_popper {
  top: 36px !important;
}
.navigation:hover,
.login:hover {
  color: #8cb3d2;
}
.main {
  width: 100%;
}
.bgi {
  width: 100%;
}
.user_info_name {
  width: 93px;
  height: 56px;
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #ffffff;
  line-height: 56px;
}
.user_info_name:hover {
  color: #8cb3d2;
}
.user_info_name_avatar {
  position: relative;
  top: 10px;
  right: 10px;
}
/* #endregion 首页 */

/* #region 登录页 */
.login_form {
  position: fixed;
  width: 418px;
  height: 489px;
  background: #ffffff;
  border-radius: 16px;
  top: 20%;
  right: 10%;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
}
.login_form_title {
  width: 160px;
  height: 56px;
  font-size: 24px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #568abf;
  line-height: 56px;
  letter-spacing: 2px;
  position: relative;
  margin-top: 72px;
}
.login_form_title_line {
  position: absolute;
  width: 172px;
  height: 10px;
  background: #dde3e8;
  border-radius: 6px;
  opacity: 0.5;
  bottom: 10px;
  left: -10px;
}
.login_form_account {
  width: 368px !important;
  margin-top: 56px;
}
.login_form_account .el-input__inner {
  width: 368px;
  height: 40px !important;
  background: #ffffff;
  border-radius: 20px;
  border: 1px solid #d9d9d9;
}
.login_form_code_box {
  width: 368px;
  display: flex;
  justify-content: space-between;
  margin-top: 32px;
}
.login_form_code {
  width: 234px !important;
}
.login_form_code .el-input__inner {
  width: 234px;
  height: 40px !important;
  background: #ffffff;
  border-radius: 20px;
  border: 1px solid #d9d9d9;
}
.login_form_code_button {
  width: 121px;
  height: 40px;
  background: #dde3e8;
  border-radius: 20px;
  font-size: 16px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #568abf;
  line-height: 40px;
  text-align: center;
}
.login_form_submit {
  width: 368px;
  height: 40px;
  background: #568abf;
  border-radius: 20px;
  margin-top: 56px;
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #ffffff;
  line-height: 40px;
  text-align: center;
  cursor: pointer;
}
/* #endregion 登录页 */

/* #region 我的课程 */
.my_lesson {
  width: 100%;
  background: #f7f9fa;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.my_lesson .box-card {
  width: 1455.4px;
  min-height: 95%;
  border-radius: 16px !important;
  margin: 10px auto;
}
.card_title {
  width: 80px;
  height: 32px;
  font-size: 20px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #568abf;
  line-height: 32px;
  margin-left: 90px;
}
.card_button {
  float: right;
  width: 78px;
  height: 28px;
  background: #568abf;
  border-radius: 6px;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #ffffff;
  line-height: 28px;
  border: 0px;
  margin: 5px 90px 0 0;
  cursor: pointer;
}
.card_empty {
  margin-top: 109px;
  margin-bottom: 130px;
}
.card_empty_text_box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.el-empty__description p {
  font-size: 16px !important;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #568abf !important;
  line-height: 24px;
  text-align: center;
}
.card_empty_text {
  width: 180px;
  height: 24px;
  font-size: 12px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #568abf;
  line-height: 24px;
  text-align: center;
}
.my_lesson_table {
  width: 100%;
}
.my_lesson_table_box {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}
.my_lesson_table_box .box-card {
  width: 30%;
  cursor: pointer;
  margin: 10px 1.5%;
}
.my_lesson_table_box_cover {
  width: 100%;
  height: 197px;
  box-shadow: 4px 4px 12px 0px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
}
.my_lesson_table_box_title {
  width: 100%;
  height: 64px;
  font-size: 21px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #0f1b41;
  line-height: 32px;
  word-break: break-all;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2; /* 这里是超出几行省略 */
  overflow: hidden;
  margin-top: 10px;
}
.my_lesson_table_box_footer {
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
}
.my_lesson_table_box_footer_time {
  height: 24px;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #0f1b41;
  line-height: 24px;
  margin-right: 16px;
}
.my_lesson_table_box_tag_enable {
  width: 66px;
  height: 26px;
  background: #ffbd5a;
  border-radius: 13px;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #ffffff;
  line-height: 26px;
  text-align: center;
}
.my_lesson_table_box_tag_disable {
  width: 66px;
  height: 26px;
  background: #e5e5e5;
  border-radius: 13px;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #8c8c8c;
  line-height: 26px;
  text-align: center;
}
.my_lesson_table_pagination {
  margin-top: 10px;
  width: 100%;
  display: flex;
  justify-content: flex-end;
}
.my_lesson_table_pagination .el-pagination {
  margin-right: 16px;
}
/* #endregion 我的课程 */

/* #region 课程信息 */
.lesson_info {
  width: 100%;
  background: #f7f9fa;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.lesson_info .box-card {
  width: 1455.4px;
  border-radius: 16px !important;
  margin: 10px auto;
}
.lesson_info_box {
  display: flex;
  justify-content: center;
}
.lesson_info_box_cover {
  width: 25%;
  height: 197px;
  border-radius: 10px;
}
.lesson_info_box_text {
  width: 60%;
  padding: 24px;
}
.lesson_info_box_text_title {
  height: 64px;
  font-size: 21px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #0f1b41;
  line-height: 32px;
  word-break: break-all;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2; /* 这里是超出几行省略 */
  overflow: hidden;
}
.lesson_info_box_text_content {
  display: flex;
  margin: 25px 0 0 0;
}
.lesson_info_box_text_time {
  height: 24px;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #0f1b41;
  line-height: 24px;
  margin-right: 16px;
}
.lesson_info_box_text_tag_enable {
  width: 66px;
  height: 26px;
  background: #ffbd5a;
  border-radius: 13px;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #ffffff;
  line-height: 26px;
  text-align: center;
}
.lesson_info_box_text_tag_disable {
  width: 66px;
  height: 26px;
  background: #e5e5e5;
  border-radius: 13px;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #8c8c8c;
  line-height: 26px;
  text-align: center;
}
.lesson_info_body {
  padding: 0 5%;
}
.lesson_info_body_list,
.lesson_info_body_list_chapter {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.lesson_info_body_list_chapter_title {
  height: 100px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
}
.lesson_info_body_list_chapter_title:hover {
  background: #eef3f8;
}
.lesson_info_body_list_chapter_single_lesson {
  height: 66px;
  width: 100%;
}
.lesson_info_body_list_chapter_title_left {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding-left: 15px;
}
.lesson_info_body_list_chapter_title_left_text1 {
  height: 24px;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #0f1b41;
  line-height: 24px;
}
.lesson_info_body_list_chapter_title_left_text2_img {
  width: 14px;
  height: 14px;
  position: relative;
  top: 2px;
}
.lesson_info_body_list_chapter_title_left_text2 {
  height: 24px;
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #5a7180;
  line-height: 24px;
}
.lesson_info_body_list_chapter_title_right {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-right: 15px;
}
.lesson_info_body_list_chapter_title_right_img {
  height: 24px;
  width: 32px;
}
.lesson_info_body_list_chapter_single_lesson {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.lesson_info_body_list_chapter_single_lesson:hover {
  background: #f7f9fa;
}
.lesson_info_body_list_chapter_single_lesson_left {
  margin-left: 66px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  position: relative;
}
.lesson_info_body_list_chapter_single_lesson_left_text1 {
  height: 24px;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #0f1b41;
  line-height: 24px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
}
.lesson_info_body_list_chapter_single_lesson_left_text2 {
  height: 24px;
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #5a7180;
  line-height: 24px;
}
.lesson_info_body_list_chapter_single_lesson_right {
  margin-right: 24px;
  display: flex;
  justify-content: right;
  width: 180px;
  align-items: center;
}
.lesson_info_body_list_chapter_title_right_img {
  width: 20px;
  height: 20px;
  cursor: pointer;
  color: #568abf;
}
.lesson_info_body_list_chapter_single_lesson_right_button1 {
  width: 78px;
  height: 28px;
  background: #ffffff;
  border-radius: 6px;
  border: 1px solid #d8d8d8;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #8c8c8c;
  line-height: 26px;
  cursor: pointer;
  margin-right: 24px;
}
.lesson_info_body_list_chapter_single_lesson_right_button2 {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #ffffff;
  line-height: 26px;
  width: 78px;
  height: 28px;
  background: #568abf;
  border-radius: 6px;
  border: 0px;
  cursor: pointer;
}
.lesson_info_body_list_chapter_single_lesson_right_img {
  width: 18px;
  height: 20px;
  margin-right: 30px;
  cursor: pointer;
}
.lesson_info_body_details {
  width: 1210px;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #5a7180;
  line-height: 24px;
}
.lesson_info_body_teachers {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.lesson_info_body_teachers_info {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 24px 0 24px 0;
}
.lesson_info_body_teachers_info_img {
  width: 150px;
  height: 200px;
  border-radius: 10px;
}
.lesson_info_body_teachers_info_content {
  width: 85%;
}
.lesson_info_body_teachers_info_content_top {
  display: flex;
  justify-content: space-between;
  margin-bottom: 12px;
}
.lesson_info_body_teachers_info_content_name {
  font-size: 21px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #0f1b41;
  line-height: 32px;
}
.lesson_info_body_teachers_info_content_button {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #8c8c8c;
  line-height: 26px;
  width: 50px;
  height: 28px;
  background: #ffffff;
  border-radius: 6px;
  border: 1px solid #d8d8d8;
  cursor: pointer;
}
.lesson_info_body_teachers_info_content_remark {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #5a7180;
  line-height: 24px;
}
/* #endregion 课程信息 */

/* #region 音视频,文档查看 */
.lesson_player {
  width: 100%;
  height: 100%;
  background: #f7f9fa;
  display: flex;
  justify-content: center;
}
.lesson_player_left {
  width: 68%;
  height: 588px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #e6eef4;
  border-radius: 16px;
  padding: 24px;
  margin-top: 10px;
  position: relative;
}
.lesson_player_left_audio {
  width: 100%;
  height: 100%;
  background: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  border-radius: 16px;
}
.lesson_player_left_disk {
  width: 356px !important;
  height: 356px;
  margin-top: 8%;
  -webkit-animation: rotation 15s linear infinite;
  animation: rotation 15s linear infinite;
}
@keyframes rotation {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}
.lesson_player_left_audio_player {
  width: 100%;
}
.lesson_player_rigth {
  width: 25%;
  height: 683px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-left: 16px;
}
.lesson_player_rigth_list {
  height: 100%;
  width: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
}
.lesson_player_rigth_active {
  background: #568abf;
  border-radius: 10px;
}
.lesson_player_rigth_body_list_chapter_single_lesson {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.lesson_player_rigth_body_list_chapter_single_lesson_right {
  margin-right: 24px;
  display: flex;
  justify-content: right;
  width: 50px;
  align-items: center;
}
.lesson_player_rigth_body_list_chapter_single_lesson_right_text1 {
  height: 24px;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #ffffff;
  line-height: 24px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
}
.lesson_player_rigth_body_list_chapter_single_lesson_right_text2 {
  height: 24px;
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #ffffff;
  line-height: 24px;
}
.lesson_info_body_list_chapter_single_lesson_left_img {
  width: 20px;
  height: 20px;
  color: #568abf;
  position: absolute !important;
  left: -42px;
  top: 13px;
}
.lesson_player_left_document {
  width: 100%;
  height: 586px;
  position: relative;
}
.fullqp {
  width: 16px;
  height: 16px;
  position: absolute;
  right: 54px;
  bottom: 0px;
  z-index: 10;
}
/* #endregion 音视频,文档查看 */

/* #region 教师简介详情 */
.teacher_detail {
  width: 100%;
  background: #f7f9fa;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.teacher_detail .box-card {
  width: 1455.4px;
  border-radius: 16px !important;
  margin: 10px auto;
}
.teacher_detail_box {
  display: flex;
  margin: 16px;
  padding: 16px;
}
.teacher_detail_box_left {
  width: 18%;
}
.teacher_detail_box_right {
  width: 78%;
}
.teacher_detail_box_left_img {
  width: 150px;
  height: 200px;
  border-radius: 10px;
  margin-left: 55px;
}
.teacher_detail_box_right_title {
  width: 100%;
  height: 32px;
  font-size: 21px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #0f1b41;
  line-height: 32px;
  margin: 12px 0;
}
.teacher_detail_box_right_info {
  width: 100%;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #5a7180;
  line-height: 24px;
}
/* #endregion 教师简介详情 */

/* #region 弹窗-兑换码 */
.dialog_excode .el-dialog {
  width: 420px;
  height: 340px;
  background: #ffffff;
  border-radius: 16px;
}
.dialog_excode_title {
  height: 38px;
  font-size: 16px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 700;
  color: #343436;
  line-height: 38px;
  margin-top: 16px;
}
.dialog-footer {
  display: flex;
  justify-content: space-around;
}
.dialog_excode_button_close,
.dialog_excode_button_confirm {
  border: 0px;
  cursor: pointer;
}
.dialog_excode_button_close {
  width: 160px;
  height: 40px;
  background: #d8d8d8;
  border-radius: 20px;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #595959;
  line-height: 40px;
}
.dialog_excode_button_confirm {
  width: 160px;
  height: 40px;
  background: #568abf;
  border-radius: 20px;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #ffffff;
  line-height: 40px;
}
.dialog_excode_input_excode input {
  font-size: 18px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #8c8c8c;
  line-height: 56px;
  width: 372px;
  height: 56px !important;
  background: #f2f2f2;
  border-radius: 16px;
}
.dialog_excode_input_vicode_box {
  width: 372px;
  height: 56px;
  /* background: #f2f2f2; */
  border-radius: 16px;
  margin-top: 18px;
  display: flex;
  align-items: center;
}
.dialog_excode_input_vicode input {
  font-size: 18px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #8c8c8c;
  line-height: 56px;
  width: 212px;
  height: 56px !important;
  background: #f2f2f2;
  border-radius: 16px 0 0 16px;
}
.dialog_excode_input_vicode_button {
  font-size: 18px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #8c8c8c;
  line-height: 56px;
  width: 279px;
  height: 56px;
  background: #dde3e8;
  border-radius: 6px;
  border-radius: 0 16px 16px 0;
  text-align: center;
  cursor: pointer;
}
/* #endregion 弹窗-兑换码 */

.warp {
  position: relative;
}

.warp .top {
  position: absolute;
  top: 50px;
  right: 0;
  z-index: 10;
  background: rgb(57, 58, 61);
  width: 100%;
  height: 40px;
  text-align: center;
  line-height: 40px;
  color: #fff;
}

.warp .botton {
  position: absolute;
  bottom: 6px;
  right: 0;
  z-index: 10;
  background: rgb(68, 68, 68);
  width: 30%;
  height: 23px;
}
</style>