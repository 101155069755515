import request from '@/utils/request.js'
const API = {
    // 创建直播
    saveLive: data => request({
        url: `/live/pc/saveLive`,
        method: 'post',
        data: data
    }),
    // 可加入直播列表
    joinLivePage: data => request({
        url: `/live/pc/joinLivePage`,
        method: 'get',
        params: data
    }),
    // 历史记录
    historyLivePage: data => request({
        url: `/live/pc/historyLivePage`,
        method: 'get',
        params: data
    }),
    // 我的直播列表
    myLivePage: data => request({
        url: `/live/pc/myLivePage`,
        method: 'get',
        params: data
    }),
    // 获取直播参与成员列表
    liveMemberPage: data => request({
        url: `/live/pc/liveMemberPage`,
        method: 'get',
        params: data
    }),
    // 添加直播观看成员
    saveLiveMember: (id, data) => request({
        url: `/live/pc/saveLiveMember/${id}`,
        method: 'post',
        data: data
    }),
    // 移除用户
    deleteLiveMember: (id, data) => request({
        url: `/live/pc/deleteLiveMember/${id}`,
        method: 'delete',
        data: data
    }),
    // 导入直播观看成员
    uploadLiveMember: (id, data) => request({
        url: `/live/pc/uploadLiveMember/${id}`,
        method: 'post',
        data: data
    }),
    // 修改直播
    updateLive: (data) => request({
        url: `/live/pc/updateLive`,
        method: 'put',
        data: data
    }),

    // 查询直播设置
    getSetting: (data) => request({
        url: `/live/pc/getSetting`,
        method: 'get',
        params: data
    }),
    // 获取直播推流地址
    getPublishUrl: (data) => request({
        url: `/live/pc/getPublishUrl`,
        method: 'get',
        params: data
    }),
    // 获取直播拉流地址
    getPlayUrl: (data, type) => request({
        url: `/live/pc/getPlayUrl/${type}`,
        method: 'get',
        params: data
    }),
    // 获取直播roomToken
    getRoomToken: (data) => request({
        url: `/live/pc/getRoomToken`,
        method: 'get',
        params: data
    }),
    // 关闭直播
    closeLive: (data) => request({
        url: `/live/pc/closeLive`,
        method: 'post',
        params: data
    }),
    // 复制直播
    copyLive: (data) => request({
        url: `/live/pc/copyLive`,
        method: 'post',
        params: data
    }),
    // 获取用户菜单权限标识
    getMenuPermission: (data) => request({
        url: `/live/pc/getMenuPermission`,
        method: 'get',
        params: data
    }),
    // 获取用户直播间权限标识
    getLivePermission: (data) => request({
        url: `/live/pc/getLivePermission`,
        method: 'get',
        params: data
    }),
    // 获取推流地址
    getPushStreamUrl: (data) => request({
        url: `/live/pc/getPushStreamUrl`,
        method: 'get',
        params: data
    }),
    // 获取拉流地址
    getPlayStreamUrl: (data) => request({
        url: `/live/pc/getPlayStreamUrl`,
        method: 'get',
        params: data
    }),
    // 获取IM-userSig
    getUserSig: (data) => request({
        url: `/live/pc/getUserSig`,
        method: 'get',
        params: data
    }),
    // 开始直播
    startLive: (data) => request({
        url: `/live/pc/startLive`,
        method: 'post',
        params: data
    }),
    // 删除历史记录
    deleteHistory: (id) => request({
        url: `/live/pc/deleteHistory/${id}`,
        method: 'delete',
    }),
    // 设为联席主持人
    setCoHost: (data) => request({
        url: `/live/pc/setCoHost`,
        method: 'post',
        data: data
    }),
    // 解除联席主持人
    rescindCoHost: (data) => request({
        url: `/live/pc/rescindCoHost`,
        method: 'post',
        data: data
    }),
    // 开始白板推流
    startWhiteboardPush: (data) => request({
        url: `/live/pc/startWhiteboardPush`,
        method: 'post',
        params: data
    }),
    // 停止白板推流
    stopWhiteboardPush: (data) => request({
        url: `/live/pc/stopWhiteboardPush`,
        method: 'post',
        params: data
    }),
    // 禁言
    setBan: (data) => request({
        url: `/live/pc/setBan`,
        method: 'post',
        data: data
    }),
    // 解除禁言
    rescindBan: (data) => request({
        url: `/live/pc/rescindBan`,
        method: 'post',
        data: data
    }),

}
export default API