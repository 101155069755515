<template>
  <div style="height: 100%;">
    <home></home>
  </div>
</template>

<script>
import home from "@/view/home/index.vue";
export default {
  name: "mainPage",
  components: {
    home,
  },
  data() {
    return {};
  },
  created() {},
  methods: {},
};
</script>

<style>
</style>